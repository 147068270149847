import utilHelper from '../utils/hostname'
const url = `${utilHelper.getApiHostname()}`
import axios from 'axios'

export default {

	getAllOrganization(perPage,page,search){
		const endpoint = perPage ? `${url}/api/organization/index?paginate=${perPage}&page=${page}&search=${search}` : `${url}/api/organization/index`;
		return axios.get(endpoint);
	},
	saveOrganization(data){
		return axios.post(`${url}/api/organization/create`,data)
	},
	getOrganizationInfo(organizationId){
		return axios.post(`${url}/api/show/organization`, organizationId)
	},
	updateOrganization(data){
		return axios.post(`${url}/api/organization/update`, data)
	},
	deleteOrganization(organizationId){
		return axios.post(`${url}/api/organization/delete`, organizationId)
	},
	storeTelegram(data){
		return axios.post(`${url}/api/telegram/store`,data)
	},
	getTelegram(data){
		return axios.get(`${url}/api/get/telegram/detail`,data)
	},
	updateTelegram(data){
		return axios.post(`${url}/api/telegram/update`,data)
	}
}