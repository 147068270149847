export default {
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "Organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])},
  "Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "Perimeter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lembangan"])},
  "Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
  "Latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latitude"])},
  "Longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitude"])},
  "Desinger_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use bulk editor (latitude, longitude separated by new line)"])},
  "Designer_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Designer"])},
  "Add_coordinate_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Coordinate"])},
  "Save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "Cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "Area_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area Status"])},
  "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
  "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "are_you_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure"])},
  "you_wont_able_to_revert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You won't be able to revert this"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
  "Send_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "activation_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation Log"])},
  "all_active_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Activation Log"])},
  "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification"])},
  "all_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Notification"])},
  "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])},
  "time": {
    "dayAgo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " day ago"])},
    "daysAgo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " days ago"])},
    "weekAgo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " week ago"])},
    "weeksAgo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " weeks ago"])},
    "monthAgo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " month ago"])},
    "monthsAgo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " months ago"])},
    "yearAgo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " year ago"])},
    "yearsAgo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " years ago"])}
  },
  "months": {
    "January": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
    "February": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
    "March": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
    "April": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
    "May": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
    "June": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
    "July": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
    "August": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
    "September": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
    "October": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
    "November": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
    "December": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])}
  },
  "dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "perimeter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lembangan"])},
  "area_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
  "area_status_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area Status"])},
  "sensor_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensor"])},
  "sensor_type_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensor Type"])},
  "organization_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])},
  "sensor_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensor List"])},
  "sensor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensor Type"])},
  "add_perimeter_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Lembangan"])},
  "field_name_perimeter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lembangan Name"])},
  "all_perimeter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lembangan List"])},
  "field_placeholder_perimeter_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eg: Lembangan Sungai Chat"])},
  "dashboard_perimeter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Lembangan"])},
  "add_area_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Area"])},
  "field_name_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area Name"])},
  "all_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area List"])},
  "field_placeholder_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eg: Sungai Wei"])},
  "update_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Area"])},
  "add_sensor_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Sensor"])},
  "sensor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensor ID"])},
  "device_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device ID"])},
  "device_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device Token"])},
  "all_sensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensor List"])},
  "field_sensor_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensor Name"])},
  "field_placeholder_sensor_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eg: Sensor Johor"])},
  "field_placeholder_sensor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eg: PS_1234"])},
  "field_placeholder_device_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eg: fc7b5a30"])},
  "field_placeholder_device_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eg: fc7b5a30"])},
  "update_sensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Sensor"])},
  "value_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eg: 9"])},
  "indicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicator"])},
  "field_indicator_caution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caution"])},
  "field_indicator_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
  "field_indicator_danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danger"])},
  "all_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization List"])},
  "add_organization_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Organization"])},
  "field_subdomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Domain"])},
  "field_organization_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Name"])},
  "field_placeholder_subdomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eg: mlk"])},
  "update_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Organization"])},
  "all_area_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area Status List"])},
  "add_area_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Area Status"])},
  "field_area_status_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area Status Name"])},
  "field_placeholder_name_area_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eg: Danger"])},
  "field_placeholder_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write in simple words (255 characters max)"])},
  "update_area_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Area Status"])},
  "all_sensor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensor Type List"])},
  "add_sensor_type_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Sensor Type"])},
  "field_placeholder_name_sensor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eg: Environment"])},
  "field_placeholder_unit_sensor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eg: mm"])},
  "field_sensor_type_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensor Type Name"])},
  "update_sensor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Sensor Type"])},
  "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Management"])},
  "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User"])},
  "field_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "view_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Role"])},
  "view_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View User"])},
  "role_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role & Permission"])},
  "list_user_without_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List User Without Role"])},
  "list_user_with_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List User With Role"])},
  "add_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Role"])},
  "field_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "verify_field_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
  "field_current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
  "update_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update User"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
  "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Verified"])},
  "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission"])},
  "add_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Permission"])},
  "update_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Permission"])},
  "function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Function"])},
  "update_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Role"])},
  "news_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "all_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All News"])},
  "news_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "date_posted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Posted"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "add_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add News"])},
  "view_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View News"])},
  "update_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update News"])},
  "time_posted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Posted"])},
  "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since"])},
  "user_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Profile"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])},
  "Update_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Password"])},
  "Telegram Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram Details"])},
  "channel_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel Name"])},
  "channel_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel Link"])},
  "channel_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel Id"])},
  "update_telegram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Telegram"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "about_ferins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About FERINS"])}
}