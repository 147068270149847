import { createStore } from 'vuex'

import AuthStore from './modules/AuthStore'
import AreaStore from './modules/AreaStore'
import LembanganStore from './modules/LembanganStore'
import AreaStatusStore from './modules/AreaStatusStore'
import OrganizationStore from './modules/OrganizationStore'
import RoleAndPermStore from './modules/RoleAndPermissionStore'
import largeSidebar from './modules/largeSidebar'
import SensorStore from './modules/SensorStore'
import SensorTypeStore from './modules/SensorTypeStore'
import HistoryDataStore from './modules/HistoricalDataStore'
import NewsDataStore from './modules/NewsDataStore'
import ProfileStore from './modules/ProfileStore'
import NotificationStore from './modules/NotificationStore'
import ActivationLogStore from './modules/ActivationLogStore'

const store = createStore({
    modules: {
        AuthStore,
        largeSidebar,
        AreaStore,
        LembanganStore,
        AreaStatusStore,
        OrganizationStore,
        RoleAndPermStore,
        SensorStore,
        SensorTypeStore,
        HistoryDataStore,
        NewsDataStore,
        ProfileStore,
        NotificationStore,
        ActivationLogStore
    },
    state(){
        return {
            showLoading: false,
            isWaterLevelDanger: false,
            isSoundPlaying: false,
        }
    },
    mutations: {
        setLoading(state, payload){
            state.showLoading = payload
        },
        setIsWaterLevelDanger(state, isDanger) {
            state.isWaterLevelDanger = isDanger;
        },
        setIsSoundPlaying(state, isPlaying) {
            state.isSoundPlaying = isPlaying;
        },
    }
})

export default store