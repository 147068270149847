import profileService from '../../services/ProfileServices'

const state = {
    userDetails: '',
}

const getters = { 
    userDetails: state => state.userDetails
}

const mutations = {
    setData(state,responseData) {
        state.userDetails = responseData
    }
}

const actions = {

    async getProfile({commit}) {
        let response = await profileService.getProfile()
        commit('setData', response.data.data)
        return response
    },

    async updateProfile({commit}, payload) {
        let response = await profileService.updateProfile(payload)
        // commit('setData', response.data.data)
        return response
    },
    async updatePassword({commit}, payload) {
        let response = await profileService.updatePassword(payload)
        // commit('setData', response.data.data)
        return response
    },
}

export default {
  state,  
  getters,
  mutations,
  actions,
  namespaced: true
}