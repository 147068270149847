import authService from "../../services/AuthServices";
import axios from "axios";

const state = {
  token: "",
  userid: "",
  user_type: 0,
  getProfile: "",
};

const getters = {
  isAuthenticated(state) {
    return !!state.token;
  },
  getToken(state) {
    return state.token;
  },
  getUserId(state) {
    return state.userid;
  },
  getUserType(state) {
    return state.user_type;
  },
  user: (state) => state.getProfile,
};

const mutations = {
  logoutSession(state) {
    localStorage.removeItem("token");
    sessionStorage.clear();
    state.token = "";
    state.userid = "";
    state.user_type = 0;
  },
  setSession(state, n) {
    state.token = n.token;
    state.userid = n.userid;
    state.user_type = n.user_type;
  },
  setData(state, responseData) {
    state.getProfile = responseData;
  },
};

const actions = {
  async login(context, data) {
    let response = await authService.login(data);
    if (response.data.data.token) {
      sessionStorage.setItem("session", JSON.stringify(response.data.data));
      context.commit("setSession", response.data.data);
      localStorage.setItem("token", response.data.data.token);
      localStorage.setItem("userRole", response.data.data.userRole.name);
      localStorage.setItem(
        "userRolePermission",
        response.data.data.userRole.group_permission
      );
      localStorage.setItem(
        "userPermission",
        JSON.stringify(response.data.data.userPermission)
      );
    }

    return response;
  },

  async register(context, data) {
    let response = await authService.register(data);
    if (response.data.data.token) {
      sessionStorage.setItem("session", JSON.stringify(response.data.data));
      context.commit("setSession", response.data.data);
    }

    return response;
  },

  getSession(context) {
    const session = sessionStorage.getItem("session");
    if (session && typeof session === "string" && session !== "") {
      const data = JSON.parse(session);
      context.commit("setSession", data);
    }
  },

  async logout(context) {
    context.commit("logoutSession");
  },

  async forgotPassword({ commit }, payload) {
    let response = await authService.forgotPassword(payload);
    return response;
  },

  async resetPassword({ commit }, payload) {
    let response = await authService.resetPassword(payload);
    return response;
  },

  async getUserInfo({ commit }, payload) {
    let response = await authService.getUserInfo(payload);
    return response;
  },

  async updateUser({ commit }, payload) {
    let response = await authService.updateUser(payload);
    return response;
  },

  async removeUserData({ commit }, payload) {
    let formData = new FormData();
    formData.append("id", payload);

    let response = await authService.deleteUser(formData);
    return response;
  },

  async verifyUser({ commit }, payload) {
    console.log("payload",payload)

    let response = await authService.verifyUser(payload);
    return response;
  },

  async getProfile({ commit }, payload) {
    let response = await await authService.getProfile(payload);
    console.log("response", response.data.data);
    commit("setData", response.data.data);
    return response;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
