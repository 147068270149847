export default {
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
  "Organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisasi"])},
  "Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokasi"])},
  "Perimeter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lembangan"])},
  "Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kawasan"])},
  "Latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latitud"])},
  "Longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitud"])},
  "Desinger_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gunakan bulk editor (latitude, longitude dipisahkan dengan garis baru)"])},
  "Designer_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gunakan Editor"])},
  "Add_coordinate_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Koordinat"])},
  "Save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])},
  "Cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
  "Area_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Kawasan"])},
  "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negeri"])},
  "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penerangan"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tindakan"])},
  "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutup"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Padam"])},
  "are_you_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda pasti"])},
  "you_wont_able_to_revert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda tidak akan dapat kembali data ini"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mel"])},
  "values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai"])},
  "Send_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantar"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "activation_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Aktif"])},
  "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikasi"])},
  "all_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua Notifikasi"])},
  "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Keluar"])},
  "time": {
    "dayAgo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " hari yang lalu"])},
    "daysAgo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " hari yang lalu"])},
    "weekAgo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " minggu yang lalu"])},
    "weeksAgo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " minggu yang lalu"])},
    "monthAgo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " bulan yang lalu"])},
    "monthsAgo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " bulan yang lalu"])},
    "yearAgo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tahun yang lalu"])},
    "yearsAgo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tahun yang lalu"])}
  },
  "months": {
    "January": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Januari"])},
    "February": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februari"])},
    "March": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mac"])},
    "April": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
    "May": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mei"])},
    "June": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jun"])},
    "July": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julai"])},
    "August": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogos"])},
    "September": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
    "October": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oktober"])},
    "November": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
    "December": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disember"])}
  },
  "dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "perimeter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lembangan"])},
  "area_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kawasan"])},
  "area_status_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Kawasan"])},
  "sensor_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensor"])},
  "sensor_type_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Sensor"])},
  "organization_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisasi"])},
  "sensor_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senarai Sensor"])},
  "sensor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Sensor"])},
  "add_perimeter_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Lembangan"])},
  "field_name_perimeter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Lembangan"])},
  "all_perimeter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senarai Lembangan"])},
  "field_placeholder_perimeter_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cth: Lembangan Sungai Chat"])},
  "dashboard_perimeter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Lembangan"])},
  "add_area_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Kawasan"])},
  "field_name_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Kawasan"])},
  "all_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senarai Kawasan"])},
  "field_placeholder_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cth: Sungai Wei"])},
  "update_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemaskini Kawasan"])},
  "add_sensor_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Sensor"])},
  "sensor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Sensor"])},
  "device_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Peranti"])},
  "device_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Peranti"])},
  "all_sensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senarai Sensor"])},
  "field_sensor_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Sensor"])},
  "field_placeholder_sensor_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cth: Sensor Johor"])},
  "field_placeholder_sensor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cth: PS_1234"])},
  "field_placeholder_device_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cth: fc7b5a30"])},
  "field_placeholder_device_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cth: fc7b5a30"])},
  "update_sensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemaskini Sensor"])},
  "value_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cth: 9"])},
  "indicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penunjuk"])},
  "field_indicator_caution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berhati-hati"])},
  "field_indicator_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amaran"])},
  "field_indicator_danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahaya"])},
  "all_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senarai Organisasi"])},
  "add_organization_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Organisasi"])},
  "field_subdomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Domain"])},
  "field_organization_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Organisasi"])},
  "field_placeholder_subdomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cth: mlk"])},
  "update_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemaskini Organisasi"])},
  "all_area_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senarai Status Kawasan"])},
  "add_area_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Status Kawasan"])},
  "field_area_status_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Status Kawasan"])},
  "field_placeholder_name_area_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cth: Bahaya"])},
  "field_placeholder_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tulis tidak lebih dari 255 patah perkataan"])},
  "update_area_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemaskini Status Kawasan"])},
  "all_sensor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senarai Jenis Sensor"])},
  "add_sensor_type_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Jenis Sensor"])},
  "field_placeholder_name_sensor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cth: Environment"])},
  "field_placeholder_unit_sensor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cth: mm"])},
  "field_sensor_type_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Jenis Sensor"])},
  "update_sensor_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemaskini Jenis Sensor"])},
  "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengurusan Pengguna"])},
  "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Pengguna"])},
  "field_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peranan"])},
  "view_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Peranan"])},
  "view_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Pengguna"])},
  "role_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peranan & Kebenaran"])},
  "list_user_without_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senarai Pengguna Tanpa Peranan"])},
  "list_user_with_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senarai Pengguna Bersama Peranan"])},
  "add_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Peranan"])},
  "field_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata Laluan"])},
  "verify_field_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengesahan Kata Laluan"])},
  "field_current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata Laluan Semasa"])},
  "update_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemaskini Pengguna"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombor Telefon"])},
  "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mel Disahkan"])},
  "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kebenaran"])},
  "add_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Kebenaran"])},
  "update_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemaskini Kebenaran"])},
  "function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fungsi"])},
  "update_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemaskini Peranan"])},
  "news_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berita"])},
  "all_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua Berita"])},
  "news_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
  "date_posted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarikh Disiarkan"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajuk"])},
  "add_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Berita"])},
  "view_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Berita"])},
  "update_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemaskini Berita"])},
  "time_posted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masa Disiarkan"])},
  "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sejak"])},
  "user_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil pengguna"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemaskini Profil"])},
  "Update_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemaskini Kata laluan"])},
  "Telegram Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butiran Telegram"])},
  "channel_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Saluran"])},
  "channel_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pautan Saluran"])},
  "channel_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pautan Id"])},
  "update_telegram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemaskini Telegram"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentang"])},
  "about_ferins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentang FERINS"])}
}