import utilHelper from '../utils/hostname'
const url = `${utilHelper.getApiHostname()}`
import axios from 'axios'

export default {

	getAllLembangan(perPage,page,search,organization){
		const endpoint = perPage ? `${url}/api/perimeter/index?paginate=${perPage}&page=${page}&search=${search}&organization=${organization}` : `${url}/api/perimeter/index`;
		return axios.get(endpoint);
	},
	getLembanganById(perimeterId){
		return axios.post(`${url}/api/data/index/level/${perimeterId}`)
	},
	saveLembangan(data){
		return axios.post(`${url}/api/perimeter/store`,data)
	},
	getWaterLevel(data){
		return axios.post(`${url}/api/data/level`,data)
	},
	getLembanganInfo(lembanganId){
		return axios.get(`${url}/api/perimeter/index?perimeterId=${lembanganId}`)
	},
	updateLembangan(data){
		return axios.post(`${url}/api/perimeter/update`, data)
	},
	deleteLembangan(id){
		return axios.post(`${url}/api/perimeter/delete`, id)
	},
	alertTelegramTeam(data){
		return axios.post(`${url}/api/alert/internal/team`, data,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
	},
	manualAlert(data){
		return axios.post(`${url}/api/manual/alert`, data)
	},
	getAllWaterLevelData(){
		return axios.get(`${url}/api/data/perimeter/water-level`)
	},
	getMinuteData(sensorId){
		return axios.post(`${url}/api/data/rain/minute`, sensorId);
	},
	getAllRainGaugeMinuteData(){
		return axios.get(`${url}/api/data/perimeter/rain/minute`)
	},
}