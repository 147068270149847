import activationLogService from '../../services/ActivationLogServices'

const state = {
    listLog: [],
}

const getters = { 
    listLog: state => state.listLog
}

const mutations = {
    setListLog(state,listLog) {
        state.listLog = listLog
    }
}

const actions = {

    async getActivationLog({commit}, payload) {
        let response = await activationLogService.getActivationLog(payload)
        commit('setListLog', response.data.data.data)
        return response
    },
}

export default {
  state,  
  getters,
  mutations,
  actions,
  namespaced: true
}