import areaStatusServices from '../../services/AreaStatusServices'
import _ from 'lodash'

const formDataPristine = {
    name: '',
    description: '',
}

const state = {
    // formData: {
    //     name: '',
    //     description: '',
    // },
    formData : _.cloneDeep(formDataPristine),
    areaStatus: []
}
const getters = {
    formData: state => state.formData,
    areaStatus: state => state.areaStatus,
}
const mutations = {
    saveFormData (state, formData) {
        state.formData = formData
    },
    setAreaStatus(state, areaStatus) {
        state.areaStatus = areaStatus
    },
    resetFormData(state) {
        state.formData = _.cloneDeep(formDataPristine)
    }
}

const actions = {
    async getAllAreaStatus({commit},payload) {

        const data = payload ? payload.perPage : 0;
        const page = payload ? payload.page : 1
        const response = await areaStatusServices.getAllAreaStatus(data,page);
        const responseData = response.data.data.data;

        commit('setAreaStatus', responseData)
        return response
    },

    async storeAreaStatus({commit}, payload) {
        let response = await areaStatusServices.saveAreaStatus(payload)
        return response
    },

    async getAreaStatusInfo({commit}, payload) {

        let formData = new FormData()
        formData.append('areaStatusId', payload.areaStatusId)

        let response = await areaStatusServices.getAreaStatus(formData)
        return response
    },

    async updateAreaStatus({commit, getters}, payload) {

        let formData = new FormData()
        formData.append('areaStatusId', payload.areaStatusId)
        formData.append('name', getters.formData.name)
        formData.append('description', getters.formData.description)

        for (var pair of formData.entries()) {
            // console.log(pair[0] + ', ' + pair[1])
        }

        let response = await areaStatusServices.updateAreaStatus(formData)
        return response
    },

    async deleteAreaStatus({commit}, payload) {

        let formData = new FormData()
        formData.append('areaStatusId', payload)

        let response = await areaStatusServices.deleteAreaStatus(formData)
        return response
    },

}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}