import lembanganServices from '../../services/LembanganServices'
import _ from 'lodash';

const zoneItemDtosPristine = [
    {
        latitude:'',
        longitude:''
    },
    {
        latitude:'',
        longitude:''
    },
    {
        latitude:'',
        longitude:''
    },{
        latitude:'',
        longitude:''
    }]
const formDataPristine = {
    name: '',
    organizationId: 1,
    bulkEdit: false,
    bulkEditData:'',
    color: '#ff8080',
    featureType: 'Polygon'
}
const state = {
    locationData: _.cloneDeep(zoneItemDtosPristine),
    formData : _.cloneDeep(formDataPristine),
    lembanganLayer:[],
    selectedLembanganLayer:[],
    lembangan: [],
    lembanganOptions: [],
    waterLevel: [],
    dateTime:[],
    allSensor:[]
}
const getters = {
    locationData: state => state.locationData,
    formData: state => state.formData,
    lembanganLayer: state => state.lembanganLayer,
    selectedLembanganLayer: state => state.selectedLembanganLayer,
    lembangan: state => state.lembangan,
    lembanganOptions: state => state.lembanganOptions,
    waterLevel: state => state.waterLevel,
    dateTime: state => state.dateTime,
    allSensor: state => state.allSensor,
}
const mutations = {
    setFormData(state, {key, value}){                
        state.formData[key] = value
    },
    resetFormData(state){
        state.formData = _.cloneDeep(formDataPristine)
        state.locationData = _.cloneDeep(zoneItemDtosPristine)
    },
    setFeaturesLembangan(state, features){                
        state.lembanganLayer = features
    },
    setSelectedLembangan(state, features){                
        state.selectedLembanganLayer = features
    },
    saveLocationData (state, locationData) {
        state.locationData = locationData
    },
    setLembangan(state, lembangan){
        state.lembanganOptions.push( {name:'Lembangan', id:0 } ) 
        state.lembanganOptions.push(...lembangan)
        state.lembangan = lembangan
    },
    clearLembangan(state){
        state.lembanganOptions = []
        state.lembangan = []
    },
    setWaterLevel(state, waterLevel){
        state.waterLevel = waterLevel
    },
    setDateTime(state, dateTime){
        state.dateTime = dateTime
    },
    setAllSensor(state, allSensor){
        state.allSensor = allSensor
    },
    addItem(state){       

        const zoneItems = _.cloneDeep(state.locationData)
        let len = state.locationData.length

        let newDtoItem = _.cloneDeep(zoneItemDtosPristine)
        if(state.formData.featureType == 'Polygon'){
            if(len < 2) {
              zoneItems.push(newDtoItem);
            } else if (len == 2) {
              zoneItems.push(newDtoItem);
              zoneItems.push(_.cloneDeep(zoneItems[0])); // close point
            } else {
              let index = len - 1;
              let closePoint = _.cloneDeep(zoneItems[index]);
              zoneItems.pop();
              zoneItems.push(newDtoItem);
              zoneItems.push(closePoint);
            }
        } else {
            zoneItems.push(newDtoItem);
        }
        state.locationData = zoneItems
    },
    setZoneItemDtos(state, itemDtos){        
        state.locationData = itemDtos.map( ({latitude, longitude}) => {
            return {latitude, longitude}
        })                
    },
    removeDtoItem(state, index){
        state.locationData.splice(index, 1)    
    },
}

const actions = {
    async getAllLembangan({commit},payload) {
        commit('clearLembangan') 
        const perPage = payload && payload.perPage ? payload.perPage : 0;
        const page = payload && payload.page ? payload.page : 1;
        const search = payload && payload.search ? payload.search : '';
        const organization = payload && payload.organization ? payload.organization : '';
        const response = await lembanganServices.getAllLembangan(perPage,page,search,organization);
        const responseData = payload == undefined || payload.organization ? response.data.data : response.data.data.data;

        // restructure data
        let features = responseData.map( feat => {
            // restructure location data
            const mappedLocationData = feat.location.map(({latitude, longitude}) => [Number(longitude), Number(latitude)])
            return {
                'id': feat.id,
                'type': 'Feature',
                'geometry': {
                    'type': 'Polygon',
                    'coordinates': [mappedLocationData]
                },
                'properties': {
                    'color': '#444444',
                    'description':'<div class="dark:text-black">Test</div>',
                    'title': feat.name,
                    // 'icon': 'theatre'
                }
            }
        })
        commit('setFeaturesLembangan', features)
        commit('setLembangan', responseData)
        return response
    },

    async getLembanganById({commit}, payload) {
        let lembanganId, sensorId;
    
        if (typeof payload === 'object') {
            lembanganId = payload.lembanganId;
            sensorId = payload.sensorId;
        } else {

            lembanganId = payload;
        }
        let response = await lembanganServices.getLembanganById(lembanganId)
        let allSensor = []

        response.data.data.map( area => {
            allSensor.push(...area.sensor)
        })

        commit('setAllSensor',allSensor)
        
        let waterLevelSensor = allSensor.filter((sensor) => {
            if (sensorId) {
                return sensor.sensor_types_id === 4 && sensor.id == sensorId;
            } else {
                return sensor.sensor_types_id === 4;
            }
        });
        
        let airHumiditySensor = allSensor.filter(sensor=>{
            return sensor.sensor_types_id == 7
        });

        let rainFallSensor = allSensor.filter(sensor => {
            if (sensorId) {
                return sensor.sensor_types_id === 5 && sensor.id == sensorId;
            } else {
                return sensor.sensor_types_id === 5;
            }
        });

        let rainGaugeSensor = allSensor.filter(sensor => {
            if (sensorId) {
                return sensor.sensorType == "RainGauge" && sensor.id == sensorId;
            } else {
                return sensor.sensorType == "RainGauge";
            }
        });

        let LEDSensor = allSensor.filter(sensor=>{
            if (sensorId) {
                return sensor.sensorType === "LED" && sensor.id == sensorId;
            } else {
                return sensor.sensorType === "LED";
            }
        })
        
        let sirenSensor = allSensor.filter(sensor=>{
            if (sensorId) {
                return sensor.sensorType === "Siren" && sensor.id == sensorId;
            } else {
                return sensor.sensorType === "Siren";
            }
        })
        
        let barSeries = []
        let barChartData = []
        for (const element of rainGaugeSensor) {

            let current_rain_reading = element.detail.minuteChart ? element.detail.minuteChart.current_reading.map(Number) : []
            let dateTime = element.detail.minuteChart ? element.detail.minuteChart.timestamp : []
            
            barSeries = [
                {
                    name: '10 Minutes Rainfall (mm/m)',
                    data: current_rain_reading
                }
            ]
            barChartData.push({
                series: barSeries,
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'bar',
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false,
                        },
                    },
                    grid: {
                        show: false,
                        padding: {
                            top: 20,
                            right: 0,
                            bottom: 0,
                            left: 0
                        }, 
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 5,
                            borderRadiusApplication: 'end',
                            dataLabels: {
                                position: 'top', // top, center, bottom
                            },
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        offsetY: -20,
                        style: {
                            fontSize: '12px',
                            colors: ["#808080"]
                        },
                    },
                    xaxis: {
                        categories: dateTime,
                        position: 'bottom',
                        labels: {
                            style: {
                                cssClass: 'dark:fill-gray-100 fill-gray-700',
                            },
                            datetimeUTC: false,
                        },
                        tickPlacement: 'on',
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: true
                        },
                        crosshairs: {
                            fill: {
                                type: 'gradient',
                                gradient: {
                                    colorFrom: '#D8E3F0',
                                    colorTo: '#BED1E6',
                                    stops: [0, 100],
                                    opacityFrom: 0.4,
                                    opacityTo: 0.5,
                                }
                            }
                        },
                        tooltip: {
                            enabled: true,
                        }
                    },
                    yaxis: {
                        // min:0,
                        // max:1,
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            show: true,
                            style: {
                                cssClass: 'dark:fill-gray-100 fill-gray-700',
                            },
                        }
                    },
                    tooltip: {
                        enabled: true,
                        theme: 'dark',
                    },
                }
            })
        }

        let series = []
        let chartData = []
        for (const element of waterLevelSensor) {
            let currentReading = element.detail ? element.detail.currentReading.map(Number) : []
            let indicator = element.indicator ? element.indicator[0] : ''
            let caution = Array(currentReading.length).fill(Number(indicator.caution))
            let warning = Array(currentReading.length).fill(Number(indicator.warning))
            let danger = Array(currentReading.length).fill(Number(indicator.danger))
            let dateTime = element.detail ? element.detail.timestamp : []

            series = [
            {
                name: 'Caution (' +caution[0] +'m)',
                type: 'line',
                data: caution
            }, {
                name: 'Warning (' +warning[0] +'m)',
                type: 'line',
                data: warning
            },
            {
                name: 'Danger (' +danger[0] +'m)',
                type: 'line',
                data: danger
            },
            {
                name: 'Current Reading',
                type: 'area',
                data: currentReading
            },
            ]
            chartData.push(
            {
                series: series,
                chartOptions: {
                    colors : ['#ffcb36', '#ff851b', '#cc0000', '#3b81f1'],
                    chart: {
                        fontFamily: 'Nunito',
                        zoom: {
                            enabled: false,
                        },
                        height: 350,
                        type: 'line',
                        toolbar: {
                            show: false,
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        dashArray: [8, 8, 8],
                        width: [3, 3, 3, 1]
                    },
                    grid: {
                        show:false
                    },
                    xaxis: {
                        type: "datetime",
                        categories: dateTime,
                        labels: {
                            style: {
                                cssClass: 'dark:fill-gray-100 fill-gray-700',
                            },
                            datetimeUTC: false,
                        },
                    },
                    yaxis: {
                        min:0,
                        max:3,
                        labels: {
                            style: {
                                
                                cssClass: 'dark:fill-gray-100 fill-gray-700',
                            }
                        },
                    },
                    tooltip: {
                        enabled: true,
                        theme: 'dark',
                        x: {
                            format: 'dd/MM/yy HH:mm',
                        },
                    },
                    legend: {
                        position: 'bottom',
                        offsetX: 0,
                        offsetY: 5,
                        labels: {
                            colors: '#808080'
                        },
                    },
                }
            })
        }

        return {response,chartData,waterLevelSensor,airHumiditySensor,LEDSensor, rainFallSensor, sirenSensor, rainGaugeSensor, barChartData}
    },

    async getWaterLevelData() {

        let response = await lembanganServices.getAllWaterLevelData()
        let waterLevelSensor = response.data.data.sensor
        let series = []
        let chartData = []
        for (const element of waterLevelSensor) {
            let currentReading = element ? element.currentReading.map(Number) : []
            let indicator = element.levelIndicator ? element.levelIndicator : ''
            let caution = Array(currentReading.length).fill(Number(indicator.caution))
            let warning = Array(currentReading.length).fill(Number(indicator.warning))
            let danger = Array(currentReading.length).fill(Number(indicator.danger))
            let dateTime = element ? element.timestamp : []

            series = [
            {
                name: 'Caution (' +caution[0] +'m)',
                type: 'line',
                data: caution
            }, {
                name: 'Warning (' +warning[0] +'m)',
                type: 'line',
                data: warning
            },
            {
                name: 'Danger (' +danger[0] +'m)',
                type: 'line',
                data: danger
            },
            {
                name: 'Current Reading',
                type: 'area',
                data: currentReading
            },
            ]
            chartData.push(
            {
                series: series,
                chartOptions: {
                    colors : ['#ffcb36', '#ff851b', '#cc0000', '#3b81f1'],
                    chart: {
                        fontFamily: 'Nunito',
                        zoom: {
                            enabled: false,
                        },
                        height: 350,
                        type: 'line',
                        toolbar: {
                            show: false,
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        dashArray: [8, 8, 8],
                        width: [3, 3, 3, 1]
                    },
                    grid: {
                        show:false
                    },
                    xaxis: {
                        type: "datetime",
                        categories: dateTime,
                        labels: {
                            style: {
                                cssClass: 'dark:fill-gray-100 fill-gray-700',
                            },
                            datetimeUTC: false,
                        },
                    },
                    yaxis: {
                        min:0,
                        max:3,
                        labels: {
                            style: {
                                cssClass: 'dark:fill-gray-100 fill-gray-700',
                            }
                        },
                    },
                    tooltip: {
                        enabled: true,
                        theme: 'dark',
                        x: {
                            format: 'dd/MM/yy HH:mm',
                        },
                    },
                    legend: {
                        position: 'bottom',
                        offsetX: 0,
                        offsetY: 5,
                        labels: {
                            colors: '#808080'
                        },
                    },
                }
            })
        }
        return {response,waterLevelSensor,chartData}
    },

    async getRainGaugeMinuteData() {

        let response = await lembanganServices.getAllRainGaugeMinuteData()
        let rainGaugeMinuteSensor = response.data.data.sensor

        let barSeries = []
        let barChartData = []
        for (const element of rainGaugeMinuteSensor) {
            let current_rain_reading = element ? element.current_reading.map(Number) : []
            let timestamp = element ? element.timestamp : []
            
            barSeries = [
                {
                    name: '10 Minutes Rainfall (mm/m)',
                    data: current_rain_reading
                }
            ]
            barChartData.push({
                series: barSeries,
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'bar',
                        toolbar: {
                            show: false
                        },
                    },
                    grid: {
                        show: false,
                        padding: {
                            top: 20,
                            right: 0,
                            bottom: 0,
                            left: 0
                        }, 
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 5,
                            borderRadiusApplication: 'end',
                            dataLabels: {
                                position: 'top', // top, center, bottom
                            },
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        offsetY: -20,
                        style: {
                            fontSize: '12px',
                            colors: ["#808080"]
                        },
                    },
                    xaxis: {
                        categories: timestamp,
                        position: 'bottom',
                        labels: {
                            style: {
                                cssClass: 'dark:fill-gray-100 fill-gray-700',
                            },
                            datetimeUTC: false,
                        },
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: true
                        },
                        tickPlacement: 'on',
                        crosshairs: {
                            fill: {
                                type: 'gradient',
                                gradient: {
                                    colorFrom: '#D8E3F0',
                                    colorTo: '#BED1E6',
                                    stops: [0, 100],
                                    opacityFrom: 0.4,
                                    opacityTo: 0.5,
                                }
                            }
                        },
                        tooltip: {
                            enabled: true,
                        }
                    },
                    yaxis: {
                        // min:0,
                        // max:1,
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            show: true,
                            style: {
                                cssClass: 'dark:fill-gray-100 fill-gray-700',
                            },
                        }
                    },
                    tooltip: {
                        enabled: true,
                        theme: 'dark',
                    },
                }
            })
        }

        return {response, rainGaugeMinuteSensor, barChartData}
    },

    async storeLembangan({commit, getters}) {
        let formData = new FormData();
        formData.append('name', getters.formData.name);
        formData.append('organizationId', getters.formData.organizationId); 
        formData.append('featureColor', getters.formData.color);
        for (let i = 0; i < getters.locationData.length; i++) {
            let latitude = getters.locationData[i].latitude;
            let longitude = getters.locationData[i].longitude;
            formData.append('location[' + i + '][latitude]', latitude);
            formData.append('location[' + i + '][longitude]', longitude);
        }
        for(let pair of formData.entries()) {
            // console.log(pair[0]+ ', '+ pair[1]);
        }
        let response = await lembanganServices.saveLembangan(formData)
        return response
    },

    convertBulkEditToDtoItem({commit, getters}){
        let coords = getters.formData.bulkEditData
        // split by newline
        .split(/\r?\n/g)         
        // remove space and collect into object
        .map((coord, i) => {
            coord.replace(/\s/g, "")
            return {
                order: i+1,
                latitude: coord.split(',')[0], 
                longitude: coord.split(',')[1]
            }
        } )    

        // if user change to circle
        if(getters.formData.zoneType == 0){coords = coords.splice(0,1)}

        // if user change to line
        // else if(getters.formData.zoneType == 2 || getters.formData.zoneType == 3){coords = coords.splice(0,2)}
        commit('setZoneItemDtos', coords)        
    },
    convertDtoItemToBulkEdit({commit, getters}){
        // just simple check to make sure the data is properly formatted
        if(!getters.locationData.length || 
            (
                getters.locationData.length == 1 &&
                (
                    getters.locationData[0].latitude == '' || 
                    getters.locationData[0].longitude == ''   
                )
            )
        ){return false}
        // we going to replicate zoneItemDtos data into bulkEdit data        
        let itemDtos = getters.locationData
        .filter( i => i.latitude || i.longitude)

        itemDtos = _.sortBy(itemDtos, ['order'])
        .map( ({latitude, longitude}, index) => `${index ? '\n' : ''}${latitude},${longitude}`)
        .join("")              

        commit('setFormData', {key:'bulkEditData', value: itemDtos})        
    },

    async getLembanganInfo({commit}, payload) {
        let response = await lembanganServices.getLembanganInfo(payload)
        // restructure location data
        const mappedLocationData = response.data.data.location.map(({latitude, longitude}) => [Number(longitude), Number(latitude)])
        let features = [{
            'id': response.data.data.id,
            'type': 'Feature',
            'geometry': {
                'type': 'Polygon',
                'coordinates': [mappedLocationData]
            },
            'properties': {
                'color': '#444444',
                'description':'<div class="dark:text-black">Test</div>',
                'title': response.data.data.name,
                // 'icon': 'theatre'
            }
        }]
        commit('setSelectedLembangan', features)

        return response
    },
    async updateLembangan({commit, getters}, payload) {
        let formData = new FormData();
        formData.append('id', payload.id)
        formData.append('name', getters.formData.name);
        formData.append('organizationId', getters.formData.organizationId); 
        formData.append('featureColor', getters.formData.color);
        for (let i = 0; i < getters.locationData.length; i++) {
            let latitude = getters.locationData[i].latitude;
            let longitude = getters.locationData[i].longitude;
            formData.append('location[' + i + '][latitude]', latitude);
            formData.append('location[' + i + '][longitude]', longitude);
        }
        for(let pair of formData.entries()) {
            // console.log(pair[0]+ ', '+ pair[1]);
        }
        let response = await lembanganServices.updateLembangan(formData)
        return response
    },

    async deleteLembangan({commit}, payload) {

        let formData = new FormData()
        formData.append('id', payload)

        let response = await lembanganServices.deleteLembangan(formData)
        return response
    },

    async alertTelegramTeam({commit, getters}, payload) {

        let response = await lembanganServices.alertTelegramTeam(payload)
        return response
    },
    async manualAlert({commit}, payload) {

        let response = await lembanganServices.manualAlert(payload)
        return response
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}