import { createRouter, createWebHistory } from 'vue-router'
import NotFound from '../views/sessions/404.vue'
import store from '../store'
import axios from 'axios'
import Swals from 'sweetalert2'
import _ from 'lodash'

const permission = JSON.parse(localStorage.getItem('userPermission'))
const userRolePermission = JSON.parse(localStorage.getItem('userRolePermission'))
const allowedRoles = ({
    dashboard: ['superAdmin', 'admin', 'user', 'public'],
    lembangan: ['superAdmin', 'admin', 'user', 'public'],
    area: ['superAdmin', 'admin', 'user', 'public'],
    areaStatus: ['superAdmin', 'admin', 'user', 'public'],
    sensor: ['superAdmin', 'admin', 'user', 'public'],
    sensorType: ['superAdmin', 'admin', 'user', 'public'],
    organization: ['superAdmin', 'admin', 'user', 'public'],
    user: ['superAdmin', 'admin', 'user', 'public'],
    role: ['superAdmin', 'admin', 'user', 'public'],
    news: ['superAdmin', 'admin', 'user', 'public'],
    permission: ['superAdmin', 'admin', 'user', 'public']
})

const userRole = localStorage.getItem('userRole')

const routes = [
// {
//     path: '/',
//     name: 'LandingPage',
//     component: () => import(/* webpackChunkName: "LandingPage" */ '../views/LandingPage.vue'),
//     meta: {
//         title: 'Landing Page',
//     }
// },
{
    path: '/triggerpage',
    name: 'Demo Trigger',
    component: () => import(/* webpackChunkName: "LandingPage" */ '../views/TriggerPage.vue'),
    meta: {
        title: 'Demo Trigger',
    }
},
{
    path: '/',
    name: 'LandingPage',
    // component: () => import(/* webpackChunkName: "login" */ '../views/Auth/Login.vue'),
    redirect: { name: 'Login' },
    meta: {
        title: 'Landing Page',
    }
},
{
    path: '/home',
    name: 'Home',
    component: () => import('../layout/index.vue'),
    redirect: '/dashboard',
    meta: {
        title: 'Home',
        requiresAuth: true,
    },

    children: [
    {
        path: '/user/manage-users',
        name: 'User Management',
        component: () => import(/* webpackChunkName: "user" */ '../views/RoleAndPermission/UserManagement.vue'),
        meta: {
            title: 'User Management',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'user-list']) && allowedRoles.user.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/user/manage-users/create',
        name: 'createUser',
        component: () => import(/* webpackChunkName: "createUser" */ '../views/RoleAndPermission/CreateUser.vue'),
        meta: {
            title: 'Create User'
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'user-create']) && allowedRoles.user.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/user/manage-users/update/:Id',
        name: 'updateUser',
        component: () => import(/* webpackChunkName: "updateUser" */ '../views/RoleAndPermission/UpdateUser.vue'),
        meta: {
            title: 'Update User'
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'user-update']) && allowedRoles.user.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/user/manage-roles',
        name: 'Role Management',
        component: () => import(/* webpackChunkName: "role" */ '../views/RoleAndPermission/RoleManagement.vue'),
        meta: {
            title: 'Role Management',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'role-list']) && allowedRoles.role.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/user/manage-roles/create',
        name: 'createRole',
        component: () => import(/* webpackChunkName: "createRole" */ '../views/RoleAndPermission/CreateRole.vue'),
        meta: {
            title: 'Create Role',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'role-create']) && allowedRoles.role.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/user/manage-roles/update/:Id',
        name: 'updateRole',
        component: () => import(/* webpackChunkName: "updateRole" */ '../views/RoleAndPermission/UpdateRole.vue'),
        meta: {
            title: 'Update Role',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'role-edit']) && allowedRoles.role.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/user/manage-permissions',
        name: 'Permission Management',
        component: () => import(/* webpackChunkName: "permission" */ '../views/RoleAndPermission/PermissionManagement.vue'),
        meta: {
            title: 'Permission Management',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'permission-list']) && allowedRoles.permission.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/user/manage-permissions/create',
        name: 'createPermission',
        component: () => import(/* webpackChunkName: "createPermission" */ '../views/RoleAndPermission/CreatePermission.vue'),
        meta: {
            title: 'Create Permission',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'permission-create']) && allowedRoles.permission.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/user/manage-permissions/update/:Id',
        name: 'updatePermission',
        component: () => import(/* webpackChunkName: "updatePermission" */ '../views/RoleAndPermission/UpdatePermission.vue'),
        meta: {
            title: 'Update Permission'
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'permission-update']) && allowedRoles.permission.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: "/notifications",
        name: "notifications",
        component: () =>import(/* webpackChunkName: "Dashboard" */ '../views/Notifications.vue'),
        meta: {
          hide: true,
        },
      },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () =>import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard/Dashboard.vue'),
        meta: {
            title: 'Dashboard',
        }
    },
    {
        path: '/dashboard-lembangan',
        name: 'Dashboard Lembangan',
        // component: () =>import(/* webpackChunkName: "dashboardLembangan" */ '../views/Dashboard/DashboardLembangan.vue'),
        component: () => import('../views/Dashboard/index.vue'),
        meta: {
            title: 'Dashboard Lembangan',
        },
        children: [
            {
                path: '/dashboard-lembangan/:id',
                name: 'Detail lembangan',
                component: () =>import(/* webpackChunkName: "dashboardLembangan" */ '../views/Dashboard/DashboardLembangan.vue'),
                meta: {
                    title: 'Detail lembangan',
                },
                // beforeEnter: (to, from, next) => {
                //     if(_.some(permission, ['name', 'role-list']) && allowedRoles.user.includes(userRole)){
                //         next()
                //     }
                //     else{
                //         next({name:'dashboard'})
                //     }
                // }
            }
        ]
    },
    {
        path: '/news',
        name: 'News',
        component: () => import(/* webpackChunkName: "news" */ '../views/News/ListNews.vue'),
        meta: {
            title: 'News',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'news-list']) && allowedRoles.news.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/news/create',
        name: 'createNews',
        component: () => import(/* webpackChunkName: "CreateNews" */ '../views/News/CreateNews.vue'),
        meta: {
            title: 'Create News',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'news-create']) && allowedRoles.news.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/news/update/:Id',
        name: 'updateNews',
        component: () => import(/* webpackChunkName: "UpdateNews" */ '../views/News/UpdateNews.vue'),
        meta: {
            title: 'Update News'
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'news-update']) && allowedRoles.news.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/news/view/:Id',
        name: 'viewNews',
        component: () => import(/* webpackChunkName: "ViewNews" */ '../views/News/ViewNews.vue'),
        meta: {
            title: 'View News'
        },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "user" */ '../views/Profile/ShowProfile.vue'),
        meta: {
            title: 'Profile',
        },
    },
    {
        path: '/activation-log',
        name: 'activationLog',
        component: () => import(/* webpackChunkName: "activationLog" */ '../views/ActivationLog/ShowActivationLog.vue'),
        meta: {
            title: 'Activation Log'
        }
    },
    {
        path: '/sensor-detail/:id',
        name: 'Sensor Detail',
        component: () =>import(/* webpackChunkName: "dashboardLembangan" */ '../views/HistoricalData/ChartHistorical.vue'),
        meta: {
            title: 'Sensor Detail',
        }
    },
    {
        path: '/sensor-rain-detail/:id',
        name: 'Sensor Rain Detail',
        component: () => import(/* webpackChunkName: "dashboardLembanganRain" */ '../views/HistoricalData/RainHourChart.vue'),
        meta: {
            title: 'Sensor Rain Detail',
        }
    },
    {
        path: '/area',
        name: 'area',
        component: () =>import(/* webpackChunkName: "ListArea" */ '../views/Area/ListArea.vue'),
        meta: {
            title: 'Area',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'area-list']) && allowedRoles.area.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/area-status',
        name: 'areaStatus',
        component: () =>import(/* webpackChunkName: "ListAreaStatus" */ '../views/Area/ListAreaStatus.vue'),
        meta: {
            title: 'Area Status',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'area-status-list']) && allowedRoles.areaStatus.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/area/create',
        name: 'createArea',
        component: () =>import(/* webpackChunkName: "CreateArea" */ '../views/Area/CreateArea.vue'),
        meta: {
            title: 'Create Area',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'area-create']) && allowedRoles.area.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/area/update/:Id',
        name: 'updateArea',
        component: () =>import(/* webpackChunkName: "UpdateArea" */ '../views/Area/UpdateArea.vue'),
        meta: {
            title: 'Update Area'
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'area-update']) && allowedRoles.area.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/area-status/create',
        name: 'createAreaStatus',
        component: () =>import(/* webpackChunkName: "CreateAreaStatus" */ '../views/Area/CreateAreaStatus.vue'),
        meta: {
            title: 'Create Area Status',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'area-status-create']) && allowedRoles.areaStatus.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/area-status/update/:Id',
        name: 'updateAreaStatus',
        component: () => import(/* webpackChunkName: "UpdateAreaStatus" */ '../views/Area/UpdateAreaStatus.vue'),
        meta: {
            title: 'Update Area Status',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'area-status-update']) && allowedRoles.areaStatus.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/lembangan',
        name: 'lembangan',
        component: () =>import(/* webpackChunkName: "ListLembangan" */ '../views/Lembangan/ListLembangan.vue'),
        meta: {
            title: 'Lembangan',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'perimeter-list']) && allowedRoles.lembangan.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/lembangan/create',
        name: 'createLembangan',
        component: () =>import(/* webpackChunkName: "CreateLembangan" */ '../views/Lembangan/CreateLembangan.vue'),
        meta: {
            title: 'Create Lembangan',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'perimeter-create']) && allowedRoles.lembangan.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/lembangan/update/:Id',
        name: 'updateLembangan',
        component: () => import(/* webpackChunkName: "UpdateLembangan" */ '../views/Lembangan/UpdateLembangan.vue'),
        meta: {
            title: 'Update Lembangan',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'perimeter-update']) && allowedRoles.lembangan.includes(userRole)){
                next()
            }
            else {
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/organizations',
        name: 'organizations',
        component: () =>import(/* webpackChunkName: "ListOrganization" */ '../views/Organization/ListOrganization.vue'),
        meta: {
            title: 'Organization',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'organization-list']) && allowedRoles.organization.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/organizations/telegram-details',
        name: 'organizations-telegram',
        component: () =>import(/* webpackChunkName: "OrganizationTelegram" */ '../views/Organization/TelegramDetails.vue'),
        meta: {
            title: 'Telegram Details',
        },
    },
    {
        path: '/organization/create',
        name: 'createOrganization',
        component: () =>import(/* webpackChunkName: "CreateOrganization" */ '../views/Organization/CreateOrganization.vue'),
        meta: {
            title: 'Create Organization',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'organization-create']) && allowedRoles.organization.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/organization/update/:Id',
        name: 'updateOrganization',
        component: () => import(/* webpackChunkName: "UpdateOrganization" */ '../views/Organization/UpdateOrganization.vue'),
        meta: {
            title: 'Update Organization',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'organization-update']) && allowedRoles.organization.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/device-status',
        name: 'deviceStatus',
        component: () => import(/* webpackChunkName: "ListSensor" */ '../views/DeviceStatus/ListDeviceStatus.vue'),
        meta: {
            title: 'Device Status',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'sensor-list']) && allowedRoles.sensor.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/sensor',
        name: 'sensor',
        component: () => import(/* webpackChunkName: "ListSensor" */ '../views/Sensor/ListSensor.vue'),
        meta: {
            title: 'Sensor',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'sensor-list']) && allowedRoles.sensor.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/sensor/create',
        name: 'createSensor',
        component: () => import(/* webpackChunkName: "CreateSensor" */ '../views/Sensor/CreateSensor.vue'),
        meta: {
            title: 'Create Sensor',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'sensor-create']) && allowedRoles.sensor.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/sensor/update/:Id',
        name: 'updateSensor',
        component: () => import(/* webpackChunkName: "UpdateSensor" */ '../views/Sensor/UpdateSensor.vue'),
        meta: {
            title: 'Update Sensor'
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'sensor-update']) && allowedRoles.sensor.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/sensor-type',
        name: 'sensorType',
        component: () => import(/* webpackChunkName: "ListSensorType" */ '../views/Sensor/ListSensorType.vue'),
        meta: {
            title: 'Sensor Type',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'sensor-type-list']) && allowedRoles.sensorType.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/sensor-type/create',
        name: 'createSensorType',
        component: () => import(/* webpackChunkName: "CreateSensorType" */ '../views/Sensor/CreateSensorType.vue'),
        meta: {
            title: 'Create Sensor Type',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'sensor-type-create']) && allowedRoles.sensorType.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/sensor-type/update/:Id',
        name: 'updateSensorType',
        component: () => import(/* webpackChunkName: "CreateSensorType" */ '../views/Sensor/UpdateSensorType.vue'),
        meta: {
            title: 'Update Sensor Type',
        },
        beforeEnter: (to, from, next) => {
            if(_.some(userRolePermission, ['name', 'sensor-type-update']) && allowedRoles.sensorType.includes(userRole)){
                next()
            }
            else{
                next({name:'dashboard'})
            }
        }
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "ResetPass" */ '../views/About/About.vue'),
        meta: {
            title: 'About   ',
        }
    },
    {
        path: '/report',
        name: 'reports',
        component: () => import(/* webpackChunkName: "ListReport" */ '../views/Report/ListReport.vue'),
        meta: {
            title: 'List Report',
        }
    },
    {
        path: '/report-summary',
        name: 'reportsummary',
        component: () => import(/* webpackChunkName: "SummaryReport" */ '../views/Report/SummaryReport.vue'),
        meta: {
            title: 'Summary Report',
        }
    },
    ],
},
{
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Auth/Login.vue'),
    meta: {
        title: 'Login',
    }
},
{
    path: '/forgot',
    name: 'Forgot Password',
    component: () => import(/* webpackChunkName: "forgot" */ '../views/Auth/ForgotPassword.vue'),
    meta: {
        title: 'Forgot Password',
    }
},
{
    path: '/reset-password',
    name: 'Reset Password',
    component: () => import(/* webpackChunkName: "ResetPass" */ '../views/Auth/ResetPassword.vue'),
    meta: {
        title: 'Reset Password',
    }
},
{
    path: '/verified',
    name: 'Verify Email',
    component: () => import(/* webpackChunkName: "ResetPass" */ '../views/Auth/VerifyEmail.vue'),
    meta: {
        title: 'Verify Email',
    }
},

// {
//     path: '/register',
//     name: 'Register',
//     component: () => import(/* webpackChunkName: "register" */ '../views/Auth/Register.vue'),
//     meta: {
//         title: 'Register',
//     }
// },
{ path: '/:path(.*)', component: NotFound },
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0 }
},

routes,
})
router.beforeEach((to, from, next) => {
    document.title = 'FERINS: ' + `${to.meta.title}` ?? `${to.name} - ${process.env.VUE_APP_TITLE}`
    // get session
    store.dispatch('AuthStore/getSession')
    const token = localStorage.getItem('token');
    // logged in page, if not signed in, redirect to login page
    if (to.matched.some(record => record.meta.requiresAuth)) {      
        if (!token) {      
            next({
                path: `/login`
            });
        } else {
            next();
        }
    } 
    // in login page, if signed in, redirect to main page
    else if(to.name == 'Login' || to.name == undefined || to.name == "LandingPage") { 

        if(token){       
            next({
                path: `/dashboard`
            });
        }
        else{     
            next()
        }
    }

    else {
        next()
    }
})
router.afterEach(() => {
    // Remove initial loading
    const GullPreLoading = document.getElementById('loading_wrap')
    if (GullPreLoading) {
        GullPreLoading.style.display = 'none'
    }
    const secondarySideNav =
    store.state.largeSidebar.sidebarToggleProperties.isSecondarySideNavOpen
    if (window.innerWidth <= 1200) {
        store.dispatch('largeSidebar/changeSidebarProperties')
        if (secondarySideNav) {
            store.dispatch('largeSidebar/changeSecondarySidebarProperties')
        }
    } else {
        if (secondarySideNav) {
            store.dispatch('largeSidebar/changeSecondarySidebarProperties')
        }
    }
})

axios.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(response => {
    // Do something with the response data
    return response;
},
error => {
    if (error.response && (error.response.status === 401)) {
        // Handle 401 error
        Swals.fire({
            title: 'Session Expired!',
            html: 'Return to login page in <b></b> seconds.',
            timer: 3000, // Set the timer to 5 seconds
            timerProgressBar: true,
        didOpen: () => {
            Swals.showLoading();
            const b = Swals.getHtmlContainer().querySelector('b');
            const timer = Swals.getTimerLeft();

            // Update the message with the remaining time
            b.textContent = (timer / 1000).toFixed(0);

            // Update the message every second
            const timerInterval = setInterval(() => {
              const remainingTime = Swals.getTimerLeft();

              if (remainingTime) {
                b.textContent = (remainingTime / 1000).toFixed(0);
              }
            }, 1000);

            // Clear the interval when the dialog is closed
            Swals.getPopup().addEventListener('mouseleave', () => {
                clearInterval(timerInterval);
            });
        },
        }).then((result) => {
            if (result.dismiss === Swals.DismissReason.timer) {
                store.dispatch('AuthStore/logout')
                location.reload()
            }
        });
    }
    if (error.message === 'Network Error' && error.code === undefined) {
        store.dispatch('AuthStore/logout')
        location.reload()
    }
    // Do something with response error
    return Promise.reject(error);
});

export default router
