import roleAndPermissionServices from "@/services/RoleAndPermissionServices";

const state = {
  allRole: [],
  allUserRole: [],
  allUserWithoutRole: [],
  allUserWithPermission: [],
  allPermission: [],
};

const getters = {
  allRole: (state) => state.allRole,
  allUserRole: (state) => state.allUserRole,
  allUserWithoutRole: (state) => state.allUserWithoutRole,
  allUserWithPermission: (state) => state.allUserWithPermission,
  allPermission: (state) => state.allPermission,
};

const mutations = {
  setAllRole(state, allRole) {
    state.allRole = allRole;
  },
  setAllUserRole(state, allUserRole) {
    state.allUserRole = allUserRole;
  },
  setAllUserWithoutRole(state, allUserWithoutRole) {
    state.allUserWithoutRole = allUserWithoutRole;
  },
  setAllUserWithPermission(state, allUserWithPermission) {
    state.allUserWithPermission = allUserWithPermission;
  },
  setAllPermission(state, allPermission) {
    state.allPermission = allPermission;
  },
};

const actions = {
  async getAllRoles({ commit }, payload) {
    // let emptArr = []
    // commit("setAllRole", emptArr);
    // console.log("payload",payload)
    const perPage = payload ? payload.perPage : "";
    const page = payload ? payload.page : "";
    const search = payload ? payload.search : "";

    let response = await roleAndPermissionServices.getAllRole(
      perPage,
      page,
      search
    );
    const responseData = payload ? response.data.data.data : response.data.data;
    // console.log("responseData", responseData);
    commit("setAllRole", responseData);
    return response;
  },
  async getAllUserRoles({ commit }, payload) {
    const perPage = payload ? payload.perPage : "";
    const page = payload ? payload.page : "";
    const search = payload ? payload.search : "";
    const response = await roleAndPermissionServices.getAllUserRole(
      perPage,
      page,
      search
    );
    // console.log("getAllUserRoles response",response.data.data)
    commit("setAllUserRole", response.data.data.data);
    return response;
  },
  async getAllUserWithoutRoles({ commit }) {
    let response = await roleAndPermissionServices.getAllUserWithoutRole();
    // console.log("getAllUserWithoutRoles response",response.data.data)
    commit("setAllUserWithoutRole", response.data.data);
    return response;
  },
  async getAllUserWithPermissions({ commit }) {
    let response = await roleAndPermissionServices.getAllUserWithPermission();
    // console.log("getAllUserWithPermissions response",response.data.data)
    commit("setAllUserWithPermission", response.data.data);
    return response;
  },

  async saveRoles({ commit }, payload) {
    let response = await roleAndPermissionServices.saveRole(payload);
    // console.log("getAllUserWithPermissions response",response.data.data)
    // commit('setAllUserWithPermission', response.data.data)
    return response;
  },

  async getAllPermissions({ commit }, payload) {
    const perPage = payload ? payload.perPage : "";
    const page = payload ? payload.page : "";
    const search = payload ? payload.search : "";
    let response = await roleAndPermissionServices.getAllPermission(
      perPage,
      page,
      search
    );
    const responseData = payload ? response.data.data.data : response.data.data;
    // console.log("response", response.data.data);
    commit("setAllPermission", responseData);
    return response;
  },

  async savePermissions({ commit }, payload) {
    let response = await roleAndPermissionServices.savePermission(payload);
    return response;
  },

  async getPermissionInfo({ commit }, payload) {
    let response = await roleAndPermissionServices.showPermission(payload);
    return response;
  },

  async updatePermissions({ commit }, payload) {
    let response = await roleAndPermissionServices.updatePermission(payload);
    return response;
  },

  async getRoleInfo({ commit }, payload) {
    let response = await roleAndPermissionServices.showRole(payload);
    return response;
  },

  async updateRole({ commit }, payload) {
    let response = await roleAndPermissionServices.updateRole(payload);
    return response;
  },

  async deleteRoles({ commit }, payload) {
    let formData = new FormData();
    formData.append("id", payload);

    let response = await roleAndPermissionServices.deleteRole(formData);
    return response;
  },

  async deletePermissions({ commit }, payload) {
    let formData = new FormData();
    formData.append("id", payload);

    let response = await roleAndPermissionServices.deletePermission(formData);
    return response;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
