import utilHelper from "../utils/hostname";
const url = `${utilHelper.getApiHostname()}`;
import axios from "axios";

export default {
  getAllRole(perPage, page, search) {
    const endpoint = perPage
      ? `${url}/api/index/role?paginate=${perPage}&page=${page}&search=${search}`
      : `${url}/api/index/role`;
    return axios.post(endpoint);
  },
  getAllUserRole(perPage, page, search) {
    const endpoint = perPage
      ? `${url}/api/users/index/role?paginate=${perPage}&page=${page}&search=${search}`
      : `${url}/api/users/index/role`;
    return axios.post(endpoint);
  },
  getAllUserWithoutRole() {
    return axios.get(`${url}/api/users/index/without/role`);
  },
  getAllUserWithPermission() {
    return axios.get(`${url}/api/users/index/permission`);
  },
  saveRole(data) {
    return axios.post(`${url}/api/store/role`, data);
  },
  getAllPermission(perPage, page, search) {
    const endpoint = perPage
      ? `${url}/api/index/permission?paginate=${perPage}&page=${page}&search=${search}`
      : `${url}/api/index/permission`;
    return axios.post(endpoint);
  },
  savePermission(data) {
    return axios.post(`${url}/api/assigned/permission`, data);
  },
  showPermission(id) {
    return axios.post(`${url}/api/users/permission/show`, id);
  },
  updatePermission(data) {
    return axios.post(`${url}/api/users/permission/update`, data);
  },
  showRole(id) {
    return axios.post(`${url}/api/users/role/show`, id);
  },
  updateRole(data) {
    return axios.post(`${url}/api/users/role/update`, data);
  },
  deleteRole(id) {
    return axios.post(`${url}/api/users/role/delete`, id);
  },
  deletePermission(id) {
    return axios.post(`${url}/api/users/permission/delete`, id);
  },
};
