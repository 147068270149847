import utilHelper from '../utils/hostname'
const url = `${utilHelper.getApiHostname()}`
import axios from 'axios'

export default {
    getAllSensorType(perPage,page) {
        const endpoint = perPage ? `${url}/api/sensor/type/index?paginate=${perPage}&page=${page}` : `${url}/api/sensor/type/index`;
		return axios.get(endpoint);
    },
    saveSensorType(data) {
        return axios.post(`${url}/api/sensor/type/create`, data)
    },
	getSensorTypeInfo(sensorTypeId){
		return axios.post(`${url}/api/sensor/type/show`,sensorTypeId)
	},
	updateSensorType(data){
		return axios.post(`${url}/api/sensor/type/update`,data)
	},
	deleteSensorType(sensorTypeId){
		return axios.post(`${url}/api/sensor/type/delete/${sensorTypeId}`)
	},
}