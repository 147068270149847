import { createApp } from "vue";

import App from "./App.vue";
import BaseCard from "./components/Base/BaseCard.vue";
import BaseBtn from "./components/Base/BaseBtn.vue";
import Breadcrumbs from "./components/Breadcrumbs.vue";

// Vue-router import
import router from "./router/router.js";
import store from "./store";
// ---- CSS Imports ----
import "./assets/scss/global.scss";
// Tailwind CSS import
import "./assets/css/tailwind.css";

// Fonts CSS
import "./assets/css/fonts.css";

// Axios
import axios from "axios";
import VueAxios from "vue-axios";

// Sweet alert
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

// perfectscrollbar plugins
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import VueApexCharts from "vue3-apexcharts";
import i18n from "./i18n";

import { defineRule } from "vee-validate";
import rules from "@vee-validate/rules";
import "./locales";
import LoadingComponent from "./components/Loading.vue";

// websokcet
import Echo from "laravel-echo";

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

// tabs
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";

import Pusher from "pusher-js";
window.Pusher = Pusher;

// image/video slider
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

// Pusher.logToConsole = true;
window.Echo = new Echo({
  broadcaster: "pusher",
  key: process.env.VUE_APP_WEBSOCKETS_KEY,
  cluster: process.env.VUE_APP_WEBSOCKETS_CLUSTER,
  encrypted: true,
});
// console.log("window.Echo.pusher",window.Echo.connector.pusher)
if (window.Echo && window.Echo.connector.pusher) {
  const pusher = window.Echo.connector.pusher;

  if (pusher.connection) {
    if (pusher.connection.state === 'connected') {
      console.log('WebSocket is connected');
    } else if (pusher.connection.state === 'connecting') {
      // If it's connecting, wait for a short delay and check again
      setTimeout(() => {
        if (pusher.connection.state === 'connected') {
          console.log('WebSocket is connected');
        } else {
          console.log('WebSocket is not connected');
        }
      }, 2000); // Adjust the delay (in milliseconds) as needed
    } else {
      console.log('WebSocket is not connected');
    }
  } else {
    console.log('WebSocket connection is not available');
  }
} else {
  console.log('WebSocket (Echo with Pusher) is not initialized');
}


// ---- Mounting packages, plugins and vue app ----

const app = createApp(App).use(i18n);

Object.keys(rules).forEach((rule) => {
  defineRule(rule, rules[rule]);
});
app.component("BaseCard", BaseCard);
app.component("BaseBtn", BaseBtn);
app.component("Breadcrumbs", Breadcrumbs);
app.component("LoadingComponent", LoadingComponent);
app.component("VPagination", VPagination);
app.component("VueperSlides", VueperSlides);
app.component("VueperSlide", VueperSlide);
app.component("TabGroup", TabGroup);
app.component("TabList", TabList);
app.component("Tab", Tab);
app.component("TabPanels", TabPanels);
app.component("TabPanel", TabPanel);
app.use(PerfectScrollbar);
app.use(VueApexCharts);
app.use(VueAxios, axios);
app.use(VueSweetalert2);
app.use(router);
app.use(store).mount("#app");
