export default {
  getApiHostname() {
    if (window.location.hostname === "ver2-staging.saifon.my") {
      return "https://server-staging.saifon.my";
    } 
    else if (window.location.hostname === "ferins.my"){
      return "https://linkapi.ferins.my";
    }
    else if (window.location.hostname === "118.107.207.248"){
      return "http://118.107.207.248:8100";
    }
    else if (window.location.hostname === "117.53.154.75"){
      return "http://117.53.154.75:8100";
    }
    else if (
      window.location.hostname == "127.0.0.1" ||
      window.location.hostname == "localhost"
    ) {
      return "http://127.0.0.1:8000";
    }
  },
  getHostnamePath() {
    if (window.location.hostname === "ver2-staging.saifon.my") {
      return "https://server-staging.saifon.my";
    } 
    else if (window.location.hostname === "ferins.my"){
      return "https://linkapi.ferins.my";
    }
    else if (window.location.hostname === "118.107.207.248"){
      return "http://118.107.207.248:8100";
    }
    else if (window.location.hostname === "117.53.154.75"){
      return "http://117.53.154.75:8100";
    }
    else if (
      window.location.hostname == "127.0.0.1" ||
      window.location.hostname == "localhost"
    ) {
      return "http://127.0.0.1:8000";
    }
  },
};