import notificationService from "../../services/NotificationServices";

const state = {
  notifications: [],
  alertNotifications: [],
};

const getters = {
  notifications: (state) => state.notifications,
  alertNotifications: (state) => state.alertNotifications,
};

const mutations = {
  setNotification(state, notifications) {
    state.notifications = notifications;
  },
  setAlertNotification(state, alertNotifications) {
    state.alertNotifications = alertNotifications;
  },
};

const actions = {
  async getAllNotification({ commit }, payload) {
    let response = await notificationService.getAllNotification(payload);
    commit("setNotification", response.data.data);
    return response;
  },

  async getAllAlertNotification({ commit }, payload) {
    let response = await notificationService.getAllAlertNotification(payload);
    commit("setAlertNotification", response.data.data);
    return response;
  },

  async markAlertAsRead() {
    let response = await notificationService.markAlertAsRead();
    return response;
  },

  async deleteAllNotification() {
    let response = await notificationService.deleteNotifications();
    return response;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
