import newsService from "../../services/NewsServices";
import axios from "axios";
import _, { get } from "lodash";

const formDataPristine = {
  title: "",
  newsCategory: "",
  description: "",
  image: "",
};

const state = {
  getAllNews: [],
  formData: _.cloneDeep(formDataPristine),
};

const getters = {
  formData: (state) => state.formData,
  news: (state) => state.getAllNews,
};

const mutations = {
  setData(state, responseData) {
    state.getAllNews = responseData;
  },

  resetFormData(state) {
    state.formData = _.cloneDeep(formDataPristine);
  },
};

const actions = {
  async getAllNews({ commit }, payload) {
    const perPage = payload ? payload.perPage : "";
    const page = payload ? payload.page : "";
    const search = payload ? payload.search : "";
    const response = await newsService.getAllNews(perPage, page, search);
    commit("setData", response.data.data.data);
    return response;
  },

  async saveNews({ commit }, data) {
    // for (var pair of data.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    let response = await newsService.saveNews(data);
    return response;
  },

  async getNews({ commit }, payload) {
    let response = await newsService.getNewsInfo(payload);
    return response;
  },

  async updateNews({ commit, getters }, data) {
    let response = await newsService.updateNews(data);
    return response;
  },

  async deleteNews({ commit }, payload) {
    let formData = new FormData();
    formData.append("id", payload);

    for (var pair of formData.entries()) {
      // console.log(pair[0] + ', ' + pair[1])
    }

    let response = await newsService.deleteNews(formData);
    return response;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
