import { localize } from '@vee-validate/i18n';
import { defineRule, configure } from 'vee-validate';
import my from '@vee-validate/i18n/dist/locale/ms_MY.json';
import en from '@vee-validate/i18n/dist/locale/en.json';    
import validator from 'validator'
import _ from 'lodash';


defineRule('minLength', (value, [limit]) => {
  // The field is empty so it should pass
  if (!value || !value.length) {
    return true;
  }
  if (value.length < limit) {
    return false;
  }
  return true;
});

defineRule('coordlist', (value) => {
    const coords = value.split(/\r?\n/g).map(coord => coord.replace(/\s/g, ""))
            
    // if some of them dont have exactly 1 comma
    if(_.some(coords, coord => coord.split(",").length - 1 !== 1)){return false}

    for(let coord of coords){           
        if( _.some(coord.split(","), c => validator.isNumeric(String(c)) == false ) ){  
            return false;
        }
    }                           

    return true
});

// custom rule messages
configure({
    generateMessage: localize({
        en: {
            fields: {
                coordinates: {
                    coordlist: 'The {field} is not in the right format'
                }
            },
        },
        my: {
            fields: {
                names: {
                    required: 'Nama adalah wajib',
                },
                coordinates: {
                    coordlist: 'Format koordinat salah'
                }
            },
        },
    }),
});

// 
configure({
    generateMessage: localize({
        en,
        my,
    }),
});