import utilHelper from "../utils/hostname";
const url = `${utilHelper.getApiHostname()}`;
import axios from "axios";

export default {
  getAllNotification(perPage) {
    return axios.get(`${url}/api/all/notification?paginate=${perPage}`);
  },
  getAllAlertNotification(perPage) {
    return axios.get(`${url}/api/alert/notification?paginate=${perPage}`);
  },
  markAlertAsRead() {
    return axios.get(`${url}/api/mark/read/notification`);
  },
  deleteNotifications() {
    return axios.post(`${url}/api/delete/user/notification`);
  },
};
