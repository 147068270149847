import historyServices from "../../services/HistoricalDataServices";

const state = {
  waterLevelData: [],
  rainGaugeData: [],
};

const getters = {
  waterLevelData: (state) => state.waterLevelData,
  rainGaugeData: (state) => state.rainGaugeData,
};

const mutations = {
  setWaterLevelData(state, waterLevelData) {
    state.waterLevelData = waterLevelData;
  },
  setRainGaugeData(state, rainGaugeData) {
    state.rainGaugeData = rainGaugeData;
  },
};

const actions = {
  async getWaterLevel({ commit, dispatch }, payload) {
    let response = await historyServices.getWaterLevel(payload);
    const sensor = response.data.data.sensor;

    let name = sensor.name;
    let series = [];
    let chartData = [];
    let currentReading = sensor.currentReading.map(Number);
    let indicator = sensor.levelIndicator;
    let caution = Array(currentReading.length).fill(Number(indicator.caution));
    let warning = Array(currentReading.length).fill(Number(indicator.warning));
    let danger = Array(currentReading.length).fill(Number(indicator.danger));
    let dateTime = sensor.timestamp;

    series = [
      {
        name: "Caution (" + caution[0] + "m)",
        type: "line",
        data: caution,
      },
      {
        name: "Warning (" + warning[0] + "m)",
        type: "line",
        data: warning,
      },
      {
        name: "Danger (" + danger[0] + "m)",
        type: "line",
        data: danger,
      },
      {
        name: "Current Reading",
        type: "area",
        data: currentReading,
      },
    ];

    chartData.push({
      series: series,
      chartOptions: {
        colors: ["#ffcb36", "#ff851b", "#cc0000", "#3b81f1"],
        chart: {
          zoom: {
            enabled: true,
          },
          height: 350,
          type: "line",
          toolbar: {
            export: {
              png: {
                filename: name + " (Water-Level)",
              },
              svg: {
                filename: name + " (Water-Level)",
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          dashArray: [8, 8, 8],
          width: [3, 3, 3, 1],
        },
        grid: {
          show: false,
        },
        xaxis: {
          type: "datetime",
          categories: dateTime,
          labels: {
            style: {
              cssClass: "dark:fill-gray-100 fill-gray-700",
            },
            datetimeUTC: false,
          },
        },
        yaxis: {
          min: 0,
          max: 10,
          labels: {
            style: {
              cssClass: "dark:fill-gray-100 fill-gray-700",
            },
          },
        },
        tooltip: {
          enabled: true,
          theme: "dark",
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
        legend: {
          position: "bottom",
          offsetX: 0,
          offsetY: 5,
          labels: {
            colors: "#808080",
          },
        },
      },
    });
    return { response, chartData, sensor };
  },
  async getRainGauge({ commit }, payload) {
    let response = await historyServices.getRainGauge(payload);

    let barChartData = [];
    const sensor = response.data.data.sensor;
    const name = sensor.name;

    let currentReading = sensor.current_reading.map(Number);
    let dateTime = sensor.timestamp;

    let barSeries = [
      {
        name: "Hourly Rainfall (mm/m)",
        data: currentReading,
      },
    ];

    barChartData.push({
      series: barSeries,
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            export: {
              csv: {
                filename: name + " (Rainfall)",
              },
              png: {
                filename: name + " (Rainfall)",
              },
              svg: {
                filename: name + " (Rainfall)",
              },
            },
          },
        },
        grid: {
          show: false,
          padding: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            borderRadiusApplication: "end",
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#808080"],
          },
        },
        xaxis: {
          categories: dateTime,
          position: "bottom",
          labels: {
            style: {
              cssClass: "dark:fill-gray-100 fill-gray-700",
            },
            datetimeUTC: false,
          },
          tickPlacement: "on",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: true,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            style: {
              cssClass: "dark:fill-gray-100 fill-gray-700",
            },
          },
        },
        tooltip: {
          enabled: true,
          theme: "dark",
        },
      },
    });

    // console.log('barChartData',barChartData)
    return { response, barChartData, sensor };
  },

  async getRainGaugeByMinuteData({ commit }, payload) {
    let response = await historyServices.getRainGaugeByMinuteData(payload);

    let barChartMinuteData = [];
    const sensor = response.data.data.sensor;
    const name = sensor.name;

    let currentReading = sensor.current_reading.map(Number);
    let dateTime = sensor.timestamp;

    let barMinuteSeries = [
      {
        name: "10 Minute Rainfall (mm/m)",
        data: currentReading,
      },
    ];

    barChartMinuteData.push({
      series: barMinuteSeries,
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            export: {
              csv: {
                filename: name + " (Rainfall)",
              },
              png: {
                filename: name + " (Rainfall)",
              },
              svg: {
                filename: name + " (Rainfall)",
              },
            },
          },
        },
        grid: {
          show: false,
          padding: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            borderRadiusApplication: "end",
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#808080"],
          },
        },
        xaxis: {
          categories: dateTime,
          position: "bottom",
          labels: {
            style: {
              cssClass: "dark:fill-gray-100 fill-gray-700",
            },
            datetimeUTC: false,
          },
          tickPlacement: "on",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: true,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            style: {
              cssClass: "dark:fill-gray-100 fill-gray-700",
            },
          },
        },
        tooltip: {
          enabled: true,
          theme: "dark",
        },
      },
    });

    return { response, barChartMinuteData, sensor };
  },
  async getAllTypeRainLevel({commit}, payload) {

    let response = await historyServices.getAllTypeRainLevel(payload)
    const sensor = response.data.data.sensor;
    return {response, sensor}
},
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
