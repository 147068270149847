import utilHelper from '../utils/hostname'
const url = `${utilHelper.getApiHostname()}`
import axios from 'axios'

export default {

	getAllSensor(data){
		return axios.post(`${url}/api/sensor/index`,data)
	},
	saveSensor(data){
		return axios.post(`${url}/api/sensor/create`,data)
	},
	getSensorInfo(sensorId){
		return axios.post(`${url}/api/sensor/show`,sensorId)
	},
	updateSensor(data){
		return axios.post(`${url}/api/sensor/update`,data)
	},
	deleteSensor(sensorId){
		return axios.post(`${url}/api/sensor/delete`,sensorId)
	},
	changeStatusLedSiren(data){
		return axios.post(`${url}/api/switch/control?pin=12&enabled=${data.enabled}&sensorId=${data.sensorId}`)
	},
	getSirenLedLog(sensorId){
		return axios.get(`${url}/api/led/log?sensorId=${sensorId}`)
	}
}