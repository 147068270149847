import areaServices from '../../services/AreaServices'
import _, { get } from 'lodash';

const zoneItemDtosPristine = [
{
    latitude:'',
    longitude:''
},
{
    latitude:'',
    longitude:''
},
{
    latitude:'',
    longitude:''
},{
    latitude:'',
    longitude:''
}]
const formDataPristine = {
    name: '',
    featureType: 'Polygon',
    state: 'Johor',
    areaStatusId: 1,
    organizationId: '',
    bulkEdit: false,
    bulkEditData:'',
    color: '#ff8080',
    perimeterId: '',
}
const state = {
    locationData: _.cloneDeep(zoneItemDtosPristine),
    formData : _.cloneDeep(formDataPristine),
    areaLayer:[],
    selectedAreaLayer:[],
    area: []
}
const getters = {
    locationData: state => state.locationData,
    formData: state => state.formData,
    areaLayer: state => state.areaLayer,
    selectedAreaLayer: state => state.selectedAreaLayer,
    area: state => state.area
}
const mutations = {
    setFormData(state, {key, value}){                
        state.formData[key] = value
    },
    resetFormData(state){
        state.formData = _.cloneDeep(formDataPristine)
        state.locationData = _.cloneDeep(zoneItemDtosPristine)
    },
    setFeatures(state, features){                
        state.areaLayer = features
    },
    setSelectedAreaLayer(state, features){                
        state.selectedAreaLayer = features
    },
    resetLocationData(state, locationData) {
        state.locationData = [_.cloneDeep(zoneItemDtosPristine)]
    },
    saveLocationData (state, locationData) {
        state.locationData = locationData
    },
    setArea(state, area){
        state.area = area
    },
    addItem(state){       

        const zoneItems = _.cloneDeep(state.locationData)
        let len = state.locationData.length

        let newDtoItem = _.cloneDeep(zoneItemDtosPristine[0])
        if(state.formData.featureType == 'Polygon'){
            if(len < 2) {
              zoneItems.push(newDtoItem);
            } else if (len == 2) {
              zoneItems.push(newDtoItem);
              zoneItems.push(_.cloneDeep(zoneItems[0])); // close point
            } else {
              let index = len - 1;
              let closePoint = _.cloneDeep(zoneItems[index]);
              zoneItems.pop();
              zoneItems.push(newDtoItem);
              zoneItems.push(closePoint);
            }
        } else {
            zoneItems.push(newDtoItem);
        }
        state.locationData = zoneItems
    },
    setZoneItemDtos(state, itemDtos){        
        state.locationData = itemDtos.map( ({latitude, longitude}) => {
            return {latitude, longitude}
        })                
    },
    removeDtoItem(state, index){
        state.locationData.splice(index, 1)    
    },
}

const actions = {
    async getAllArea({commit},payload) {
        const data = payload && payload.perPage ? payload.perPage : 0;
        const page = payload && payload.page ? payload.page : 1;
        const search = payload && payload.search ? payload.search : '';
        const perimeter = payload && payload.lembanganId ? payload.lembanganId : '';
        const response = await areaServices.getAllArea(data,page,search,perimeter);
        const responseData = payload ? response.data.data.data : response.data.data;
        // restructure data
        let features = responseData.map( feat => {
            let feature_color = ""
            switch (feat.areaStatus) {
            case 'Normal': // Normal
                feature_color = "#069B2E";
                break;
            case 'Waspada': // Waspada
                feature_color = "#FFF127";
                break;
            case 'Amaran': // Waspada
                feature_color = "#FF6F00";
                break;
            case 'Bahaya': // danger
                feature_color = "#FF2727";
                break;
            
            }
            // restructure location data
            const mappedLocationData = feat.location.map(({latitude, longitude}) => [Number(longitude), Number(latitude)])

            let coordinates = ''
            switch (feat.feature_type) {
              case 'Polygon':
                coordinates = [mappedLocationData]
                break
              case 'LineString':
                coordinates = mappedLocationData
                break
              default:
                coordinates = mappedLocationData[0]
                break
            }
            return {
                'id': feat.id,
                'type': 'Feature',
                'geometry': {
                    'type': feat.feature_type,
                    'coordinates': coordinates
                },
                'properties': {
                    color: feature_color,
                    'description':
                    '<div class="dark:text-black">Test</div>',
                    'title': feat.name,
                    'perimeter_id': feat.perimeter_id,
                    'perimeterName': feat.perimeterName,
                    // 'icon': 'theatre'
                }
            }
        })                
        commit('setFeatures', features)
        commit('setArea', responseData)
        return response
    },

    async getAllAreaStatus({commit}) {

        let response = await areaServices.getAllAreaStatus()
        commit('setArea', response.data.data)
        return response
    },

    async storeArea({commit, getters}) {
        let formData = new FormData();
        formData.append('name', getters.formData.name);
        formData.append('state', getters.formData.state);
        formData.append('featureType', getters.formData.featureType);
        formData.append('areaStatusId', getters.formData.areaStatusId);
        formData.append('organizationId', getters.formData.organizationId);
        formData.append('featureColor', getters.formData.color);
        formData.append('perimeterId', getters.formData.perimeterId);
        for (var i = 0; i < getters.locationData.length; i++) {
            let latitude = getters.locationData[i].latitude;
            let longitude = getters.locationData[i].longitude;
            formData.append('location[' + i + '][latitude]', latitude);
            formData.append('location[' + i + '][longitude]', longitude);
        }
        for(var pair of formData.entries()) {
            // console.log(pair[0]+ ', '+ pair[1]);
        }
        let response = await areaServices.saveArea(formData)
        return response
    },
    convertBulkEditToDtoItem({commit, getters}){
        let coords = getters.formData.bulkEditData
        // split by newline
        .split(/\r?\n/g)         
        // remove space and collect into object
        .map((coord, i) => {
            coord.replace(/\s/g, "")
            return {
                order: i+1,
                latitude: coord.split(',')[0], 
                longitude: coord.split(',')[1]
            }
        } )    

        // if user change to circle
        if(getters.formData.zoneType == 0){coords = coords.splice(0,1)}

        // if user change to line
        // else if(getters.formData.zoneType == 2 || getters.formData.zoneType == 3){coords = coords.splice(0,2)}
        commit('setZoneItemDtos', coords)        
    },
    convertDtoItemToBulkEdit({commit, getters}){
        // just simple check to make sure the data is properly formatted
        if(!getters.locationData.length || 
            (
                getters.locationData.length == 1 &&
                (
                    getters.locationData[0].latitude == '' || 
                    getters.locationData[0].longitude == ''   
                )
            )
        ){return false}
        // we going to replicate zoneItemDtos data into bulkEdit data        
        let itemDtos = getters.locationData
        .filter( i => i.latitude || i.longitude)

        itemDtos = _.sortBy(itemDtos, ['order'])
        .map( ({latitude, longitude}, index) => `${index ? '\n' : ''}${latitude},${longitude}`)
        .join("")              

        commit('setFormData', {key:'bulkEditData', value: itemDtos})        
    },

    async getAreaInfo({commit}, payload) {
        
        let formData = new FormData()
        formData.append('areaId', payload.areaId)

        let response = await areaServices.getAreaInfo(formData)

        // restructure location data
        const mappedLocationData = response.data.data.location.map(({latitude, longitude}) => [Number(longitude), Number(latitude)])
        let features = [{
            'id': response.data.data.id,
            'type': 'Feature',
            'geometry': {
                'type': 'Polygon',
                'coordinates': [mappedLocationData]
            },
            'properties': {
                'color': '#444444',
                'description':'<div class="dark:text-black">Test</div>',
                'title': response.data.data.name,
            }
        }]
        commit('setSelectedAreaLayer', features)

        return response
    },

    async updateArea({commit, getters}, payload) {

        let formData = new FormData()
        formData.append('id', payload.areaId)
        formData.append('name', getters.formData.name)
        formData.append('areaStatusId', getters.formData.areaStatusId)
        formData.append('perimeterId', getters.formData.perimeterId)
        formData.append('organizationId', getters.formData.organizationId)
        formData.append('featureType', getters.formData.featureType)
        formData.append('featureColor', getters.formData.color)
        formData.append('state', getters.formData.state)

        for (var i = 0; i < getters.locationData.length; i++) {
            let latitude = getters.locationData[i].latitude;
            let longitude = getters.locationData[i].longitude;
            formData.append('location[' + i + '][latitude]', latitude);
            formData.append('location[' + i + '][longitude]', longitude);
        }

        for(var pair of formData.entries()) {
            // console.log(pair[0]+ ', '+ pair[1]);
        }

        let response = await areaServices.updateArea(formData)
        return response
    },

    async deleteArea({commit}, payload) {
        
        let formData = new FormData()
        formData.append('areaId', payload)

        let response = await areaServices.deleteArea(formData)
        return response
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}