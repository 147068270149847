import utilHelper from '../utils/hostname'
const url = `${utilHelper.getApiHostname()}`
import axios from 'axios'

export default {
	
	getAllAreaStatus(perPage,page){
		const endpoint = perPage ? `${url}/api/area/status/index?paginate=${perPage}&page=${page}` : `${url}/api/area/status/index`;
		return axios.get(endpoint);
	},
	saveAreaStatus(data){
		return axios.post(`${url}/api/area/status/create`,data)
	},
	getAreaStatus(areaStatusId){
		return axios.post(`${url}/api/area/status/show`,areaStatusId)
	},
	updateAreaStatus(data){
		return axios.post(`${url}/api/area/status/update`,data)
	},
	deleteAreaStatus(areaStatusId){
		return axios.post(`${url}/api/area/status/delete`,areaStatusId)
	},
}