<template>
    <div>
        <LoadingComponent v-if="showLoading"/>
        <router-view />
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        name: 'app',
        computed: {
            ...mapState({
                showLoading: (state) => state.showLoading,
            }),
        },
        mounted() {
            // window.Echo.channel('water-level').listen('WaterLevel', (e)=>{
            //     console.log("Event",e)
            // })
        }
    }
</script>

<style>
</style>