import utilHelper from "../utils/hostname";
const url = `${utilHelper.getApiHostname()}`;
import axios from "axios";

export default {
  getAllNews(perPage, page, search) {
    const endpoint = perPage
      ? `${url}/api/news/index?paginate=${perPage}&page=${page}&search=${search}`
      : `${url}/api/news/index`;
    return axios.post(endpoint);
  },
  saveNews(data) {
    return axios.post(`${url}/api/news/create`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getNewsInfo(newsId) {
    return axios.post(`${url}/api/news/show`, newsId);
  },
  updateNews(data) {
    return axios.post(`${url}/api/news/update`, data);
  },
  deleteNews(newsId) {
    return axios.post(`${url}/api/news/delete`, newsId);
  },
};
