import utilHelper from '../utils/hostname'
const url = `${utilHelper.getApiHostname()}`
import axios from 'axios'

export default {

	getAllArea(perPage,page,search,perimeter){
		const endpoint = perPage || perimeter ? `${url}/api/area/index?paginate=${perPage}&page=${page}&search=${search}&perimeter=${perimeter}` : `${url}/api/area/index`;
		return axios.get(endpoint);
	},
	saveArea(data){
		return axios.post(`${url}/api/area/create`,data)
	},
	getAreaInfo(areaId){
		return axios.post(`${url}/api/area/show`,areaId)
	},
	updateArea(data){
		return axios.post(`${url}/api/area/update`,data)
	},
	deleteArea(areaId){
		return axios.post(`${url}/api/area/delete`,areaId)
	},

}