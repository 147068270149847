import utilHelper from '../utils/hostname'
const url = `${utilHelper.getApiHostname()}`
import axios from 'axios'

export default {

	getProfile() { 
		return axios.get(`${url}/api/profile`)
	},
    updateProfile(data) {
        return axios.post(`${url}/api/update`, data)
    },
    updatePassword(data) {
        return axios.post(`${url}/api/update/password`, data)
    },

}