import organizationServices from '../../services/OrganizationServices'
import _, { get } from 'lodash';

const zoneItemDtosPristine = {
    latitude:'',
    longitude:''
}

const formDataPristine = {
    name: '',
    subdomain: '',
    bulkEdit: false,
    bulkEditData:'',
    organizationId: 1,
    featureType: 'Point',
}

const state = {
    organization: [],
    formData: _.cloneDeep(formDataPristine),
    locationData: [_.cloneDeep(zoneItemDtosPristine)],
    telegramDetails: []
}
const getters = {
    formData: state => state.formData,
    organization: state => state.organization,
    locationData: state => state.locationData,
    telegramDetails: state => state.telegramDetails,
}
const mutations = {
    saveFormData (state, formData) {
        state.formData = formData
    },
    saveTelegramDetails (state, data) {
        state.telegramDetails = data
    },
    setAreaStatus(state, organization) {
        state.organization = organization
    },
    resetFormData(state) {
        state.formData = _.cloneDeep(formDataPristine)
        state.locationData = [_.cloneDeep(zoneItemDtosPristine)]
    },
    saveLocationData(state, locationData) {
        state.locationData = locationData
    },
}

const actions = {
    async getAllOrganization({commit},payload) {

        const data = payload ? payload.perPage : 0;
        const page = payload ? payload.page : 1;
        const search = payload ? payload.search : '';
        const response = await organizationServices.getAllOrganization(data,page,search);
        const responseData = response.data.data.data;
        // let response = await organizationServices.getAllOrganization()
        commit('setAreaStatus', responseData)
        return response
    },

    async saveOrganization({commit, getters}, payload) {

        let formData = new FormData()
        formData.append('name', payload.name)
        formData.append('subdomain', payload.subdomain)
        formData.append('latitude', getters.locationData[0].latitude)
        formData.append('longitude', getters.locationData[0].longitude)

        for (var pair of formData.entries()) {
            // console.log(pair[0] + ', ' + pair[1])
        }

        let response = await organizationServices.saveOrganization(formData)
        return response
    },

    async getOrganizationInfo({commit}, payload) {

        let formData = new FormData()
        formData.append('organizationId', payload.organizationId)

        for (var pair of formData.entries()) {
            // console.log(pair[0] + ', ' + pair[1])
        }

        let response = await organizationServices.getOrganizationInfo(formData)
        return response
    },

    async updateOrganization({commit, getters}, payload) {

        let formData = new FormData()
        formData.append('id', payload.organizationId)
        formData.append('name', getters.formData.name)
        formData.append('subdomain', getters.formData.subdomain)
        formData.append('latitude', getters.locationData[0].latitude)
        formData.append('longitude', getters.locationData[0].longitude)

        for (var pair of formData.entries()) {
            // console.log(pair[0] + ', ' + pair[1])
        }

        let response = await organizationServices.updateOrganization(formData)
        return response
    },

    async deleteOrganization({commit}, payload) {

        let formData = new FormData()
        formData.append('id', payload)

        for (var pair of formData.entries()) {
            // console.log(pair[0] + ', ' + pair[1])
        }

        let response = await organizationServices.deleteOrganization(formData)
        return response
    },

    async getTelegramDetails({commit}){

        let response = await organizationServices.getTelegram()
        commit('saveTelegramDetails', response.data.data)
        return response
    },

    async saveTelegram({commit},payload) {

        let formData = new FormData()
        formData.append('channelName', payload.channelName)
        formData.append('channelLink', payload.channelLink)
        formData.append('channelId', payload.channelId)

        let response = await organizationServices.storeTelegram(formData)
        return response
    },

    async updateTelegram({commit},payload) {

        let formData = new FormData()
        formData.append('telegramId', payload.telegramId)
        formData.append('channelName', payload.channelName)
        formData.append('channelLink', payload.channelLink)
        formData.append('channelId', payload.channelId)

        let response = await organizationServices.updateTelegram(formData)
        return response
    },

}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}