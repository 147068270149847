import sensorTypeServices from "@/services/SensorTypeServices";
import _, { get } from 'lodash';

const formDataPristine = {
    name: '',
    unit: '',
}

const state = {
    sensorType: [],
    formData: _.cloneDeep(formDataPristine)
}

const getters = {
    sensorType: state => state.sensorType,
    formData: state => state.formData,
}

const mutations = {
    setSensorType(state, sensorType) {
        state.sensorType = sensorType
    },
    resetFormData(state) {
        state.formData = _.cloneDeep(formDataPristine)
    },
}

const actions = {
    async getAllSensorType({commit},payload) {

        const data = payload ? payload.perPage : 0;
        const page = payload ? payload.page : 1
        const response = await sensorTypeServices.getAllSensorType(data,page);
        const responseData = response.data.data.data;
        commit('setSensorType', responseData)
        return response
    },

    async storeSensorType({getters}) {

        let formData = new FormData();
        formData.append('name', getters.formData.name)
        formData.append('unit', getters.formData.unit)

        for (var pair of formData.entries()) {
            // console.log(pair[0] + ', ' + pair[1])
        }

        let response = await sensorTypeServices.saveSensorType(formData)
        
        return response
    },

    async getSensorTypeInfo({commit}, payload) {

        let formData = new FormData()
        formData.append('sensorTypeId', payload.sensorTypeId)

        let response = await sensorTypeServices.getSensorTypeInfo(formData)
        return response
    },

    async updateSensorType({commit, getters}, payload) {

        let formData = new FormData()
        formData.append('sensorTypeId', payload.sensorTypeId)
        formData.append('name', getters.formData.name)
        formData.append('unit', getters.formData.unit)

        for (var pair of formData.entries()) {
            // console.log(pair[0] + ', ' + pair[1])
        }

        let response = await sensorTypeServices.updateSensorType(formData)
        return response
    },

    async deleteSensorType({commit}, payload) {

        let response = await sensorTypeServices.deleteSensorType(payload)
        return response
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}