import sensorServices from "../../services/SensorServices";
import _, { get } from "lodash";

const zoneItemDtosPristine = {
  latitude: "",
  longitude: "",
};

const formDataPristine = {
  name: "",
  idWasp: "",
  areaId: 1,
  featureType: "Point",
  sensorTypesId: 1,
  indicator: [
    {
      caution: "",
      warning: "",
      danger: "",
    },
  ],
  device_id: "",
};

const state = {
  sensor: [],
  formData: _.cloneDeep(formDataPristine),
  locationData: [_.cloneDeep(zoneItemDtosPristine)],
  sensorLayer: [],
  logData: [],
};
const getters = {
  sensor: (state) => state.sensor,
  formData: (state) => state.formData,
  locationData: (state) => state.locationData,
  sensorLayer: (state) => state.sensorLayer,
  logData: (state) => state.logData,
};
const mutations = {
  setSensor(state, sensor) {
    state.sensor = sensor;
  },
  resetFormData(state) {
    state.formData = _.cloneDeep(formDataPristine);
    state.locationData = [_.cloneDeep(zoneItemDtosPristine)];
  },
  setFeatures(state, features) {
    state.sensorLayer = features;
  },
  resetLocationData(state, locationData) {
    state.locationData = [_.cloneDeep(zoneItemDtosPristine)];
  },
  saveLocationData(state, locationData) {
    state.locationData = locationData;
  },
  setLogData(state, logData) {
    state.logData = logData;
  },
};

const actions = {
  async getAllSensor({ commit }, payload) {
    const { legend = [], paginate = 0, page = 1, search = "" } = payload;
    const formData = new FormData();
    formData.append("paginate", paginate);
    formData.append("page", page);
    formData.append("search", search);

    legend.forEach((legendItem, index) => {
      formData.append(`legend[${index}]`, legendItem);
    });

    const response = await sensorServices.getAllSensor(formData);
    const responseData = payload.legend
      ? response.data.data
      : response.data.data.data;

    let features = responseData.map((feat) => {
      const {
        latitude,
        longitude,
        sensor_type_name,
        sensor_details,
        id,
        name,
      } = feat;
      const latlng = [Number(longitude), Number(latitude)];
      let sensorType = sensor_type_name;
      if (sensor_type_name === "US" && sensor_details.current_indicator_level) {
        switch (sensor_details.current_indicator_level) {
          case "Normal":
            sensorType = "water-level-normal";
            break;
          case "Caution":
            sensorType = "water-level-caution";
            break;
          case "Warning":
            sensorType = "water-level-warning";
            break;
          case "Danger":
            sensorType = "water-level-danger";
            break;
          default:
            sensorType = sensor_type_name;
            break;
        }
      } else if (sensor_type_name === "LED" && feat.led === "OFFLINE") {
        sensorType = "LED-offline";
      } else if (sensor_type_name === "Siren" && feat.siren === "OFFLINE") {
        sensorType = "Siren-offline";
      } else if (sensor_type_name === "LED" && feat.led === "ON") {
        sensorType = "LED-alert";
      } else if (sensor_type_name === "Siren" && feat.siren === "ON") {
        sensorType = "Siren-alert";
      }

      return {
        id,
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: latlng,
        },
        properties: {
          perimeter_id: feat.perimeter_id,
          perimeterName: feat.perimeter_name,
          isEnable: true,
          sensorType,
          description: '<div class="dark:text-black">Test</div>',
          title: name,
        },
      };
    });

    commit("setFeatures", features);
    commit("setSensor", responseData);
    return response;
  },

  async storeSensor({ getters }) {
    // console.log("getters", getters.formData)
    // console.log("locationData", getters.locationData)
    console.log(
      "getters.formData.indicator[0].caution",
      getters.formData.indicator[0].caution
    );

    let formData = new FormData();
    formData.append("name", getters.formData.name);
    formData.append("idWasp", getters.formData.idWasp);
    formData.append("areaId", getters.formData.areaId);
    formData.append("latitude", getters.locationData[0].latitude);
    formData.append("longitude", getters.locationData[0].longitude);
    formData.append("sensorTypesId", getters.formData.sensorTypesId);
    formData.append("device_id", getters.formData.device_id);
    if (getters.formData.indicator[0].caution != "") {
      formData.append(
        "indicator[caution]",
        getters.formData.indicator[0].caution
      );
      formData.append(
        "indicator[warning]",
        getters.formData.indicator[0].warning
      );
      formData.append(
        "indicator[danger]",
        getters.formData.indicator[0].danger
      );
    }

    let response = await sensorServices.saveSensor(formData);
    return response;
  },

  async getSensorInfo({ commit }, payload) {
    let formData = new FormData();
    formData.append("id", payload.sensorId);

    for (var pair of formData.entries()) {
      // console.log(pair[0] + ', ' + pair[1])
    }

    let response = await sensorServices.getSensorInfo(formData);
    return response;
  },

  async updateSensor({ commit, getters }, payload) {
    let formData = new FormData();
    formData.append("sensorId", payload.sensorId);
    formData.append("name", getters.formData.name);
    formData.append("idWasp", getters.formData.idWasp);
    formData.append("areaId", getters.formData.areaId);
    formData.append("sensorTypesId", getters.formData.sensorTypesId);
    formData.append("latitude", getters.locationData[0].latitude);
    formData.append("longitude", getters.locationData[0].longitude);
    formData.append("device_id", getters.formData.device_id);
    formData.append("device_token", getters.formData.device_token);
    if (
      getters.formData.indicator[0] != null &&
      getters.formData.indicator[0].caution != ""
    ) {
      formData.append(
        "indicator[caution]",
        getters.formData.indicator[0].caution
      );
      formData.append(
        "indicator[warning]",
        getters.formData.indicator[0].warning
      );
      formData.append(
        "indicator[danger]",
        getters.formData.indicator[0].danger
      );
    }

    let response = await sensorServices.updateSensor(formData);
    return response;
  },

  async deleteSensor({ commit }, payload) {
    // console.log('payload', payload)

    let formData = new FormData();
    formData.append("id", payload);

    let response = await sensorServices.deleteSensor(formData);
    return response;
  },
  async changeStatusLedSiren({ commit }, payload) {
    let formData = {
      enabled: payload.status,
      sensorId: payload.sensorId,
    };
    let response = await sensorServices.changeStatusLedSiren(formData);
    return response;
  },

  async getSirenLedLog({ commit }, payload) {
    let response = await sensorServices.getSirenLedLog(payload);
    commit("setLogData", response.data.data.data);
    return response;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
