import utilHelper from "../utils/hostname";
const url = `${utilHelper.getApiHostname()}`;
import axios from "axios";

export default {
  getWaterLevel(data) {
    // data{ sensorId, datefrom, dateto }
    return axios.post(`${url}/api/data/water/level`, data);
  },
  getRainGauge(data) {
    // data{ sensorId, datefrom, dateto }
    return axios.post(`${url}/api/data/rain/hour/level`, data);
  },
  getRainGaugeByMinuteData(data) {
    // data{ sensorId, datefrom, dateto }
    return axios.post(`${url}/api/data/rain/minute/level`, data);
  },
	getAllTypeRainLevel(data){
		return axios.post(`${url}/api/data/rain/alltype/level`, data)
	}
};
