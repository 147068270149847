import utilHelper from '../utils/hostname'
const url = `${utilHelper.getApiHostname()}`
import axios from 'axios'

export default {

	getActivationLog(type){
		return axios.post(`${url}/api/log/activation?type=${type}`)
	},
	
}