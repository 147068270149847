import utilHelper from '../utils/hostname'
const url = `${utilHelper.getApiHostname()}`
import axios from 'axios'

export default {

	login(data) {
		return axios.post(`${url}/api/login`, data)
	},
	register(data){
		return axios.post(`${url}/api/register`, data)
	},
	logout(data){
		return axios.get(`${url}/api/logout`, data)
	},
	forgotPassword(data) {
		return axios.post(`${url}/api/forgot-password`, data)
	},
	resetPassword(data) {
		return axios.post(`${url}/api/reset-password`, data)
	},
	getUserInfo(userId) {
		return axios.post(`${url}/api/user/show`, userId)
	},
	updateUser(data) {
		return axios.post(`${url}/api/user/update`, data)
	},
	deleteUser(id) { 
		return axios.post(`${url}/api/user/delete`, id)
	},
	verifyUser(data){
		return axios.get(`${url}/api/verified/${data.id}/${data.signature}`)
	}

}